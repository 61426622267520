import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { eng } from './Components/I18n/en'
import { esp } from './Components/I18n/es'

const fallbackLng = ['es'];
const availableLanguages = ['en', 'es'];

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.
    lng: 'es',
    debug: false,

    whitelist: availableLanguages,
    resources: {
      en: {
        translation: eng
      },
      es: {
        translation: esp
      }
    }
  });

export default i18n;