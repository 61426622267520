import Header from "./Components/Header/Header";
import Home from "./Pages/Home";
import DetailProject from "./Pages/DetailProject";
import Process from "./Pages/Process";
import WOW from "wowjs"
import 'animate.css'
import './App.css'
import { useTranslation } from "react-i18next";
import { Routes, Route, useNavigate } from "react-router-dom";
import React,{ useState, useEffect} from 'react';
import './i18nextConf'
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";

function App() {
  const [open, setOpen] = useState('');

  useEffect(()=>{
    new WOW.WOW({
      live:false
    }).init();

  },[]);
  return (
    <div className={`App ${open}`}>
        <ScrollToTop />
        <Header 
          open={open}
          setOpen={setOpen}></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/detalle/:project" element={<DetailProject />} />
          <Route path="/proceso" element={<Process />} />
        </Routes>
        
    </div>
  );
}

export default App;
