export const esp = {
    CONCEPT_RENDERINC: 'Agencia de comunicación arquitectónica enfocada en generar experiencias interactivas que llevan la imaginación a la realidad.',
    BTN_SOLUTIONS: 'Conoce nuestras soluciones',
    BTN_PORTFOLIO: 'Ver Portafolio',
    TITLE_SOLUTIONS: 'SOLUCIONES',
    CONCEPT_SOLUTIONS: 'Te ayudamos a generar herramientas para la venta especializada de tu proyecto inmobiliario.',
    TITLE_VELASUR: 'HERRAMIENTAS DE VENTA INNOVADORAS',
    CONCEPT_VELASUR: 'Integración de renders HD como herramientas de venta para proyectos en desarrollo.',
    KEY_WORDS_VELASUR: 'VELASUR | QUERÉTARO | SITIO WEB | SIMULADOR DE VENTAS | VIDEO PROMOCIONAL',
    BTN_PROYECTO: 'Ver proyecto',
    TITLE_EVAMAR: 'IMPULSA TU PROYECTO INMOBILIARIO',
    CONCEPT_EVAMAR: 'Espacios con calides que incrementan la calidad de vida en familia y comodidad.',
    KEY_WORDS_EVAMAR: 'EVAMAR | SONORA | CASO FULL SERVICE',
    TITLE_NOWUS: 'IMAGINA, VISUALIZA',
    CONCEPT_NOWUS: 'Redefiniendo el concepto de oficina con espacios corporativos y comerciales vanguardistas.',
    KEY_WORDS_NOWUS: 'NOWUS | QUERÉTARO | CASO FULL SERVICE',
    TITLE_SIMULADOR: ' SIMULADOR DE VENTAS',
    CONCEPT_SIMULADOR: 'Incrementa tus ventas con una herramienta interactiva para promover tu desarrollo en línea, te mostramos como funciona.',
    BTN_SCHEDULE: 'Agendar una demostracion',
    BTN_DATA: 'Déjanos tus datos para contactarte',
    TITLE_LEADS: 'PERFILA LEADS',
    CONCEPT_LEADS: 'Permite conocer el proyecto con sus características, permitiendo comparar en línea con otros proyectos existentes conforme al flujo de compra actual de los clientes.',
    TITLE_CRM: 'CRM',
    CONCEPT_CRM: 'Conecta tu CRM con tu proyecto de una manera gráfica y atractiva, mostrando inventario actualizado y dándole seguimiento a tus clientes.',
    TITLE_PAY: 'RESERVA CON PAGO EN LÍNEA',
    CONCEPT_PAY: 'Habilitamos la reserva en línea vía pagos electrónico, para incentivar la compra expréx.',
    TITLE_ADMIN: 'PANEL ADMINISTRATIVO',
    CONCEPT_ADMIN: 'Diseñamos un panel a la medida de tus necesidades para que tú controles las características de tu proyecto.',
    TITLE_RESPONSIVE: 'RESPONSIVO',
    CONCEPT_RESPONSIVE: 'Plataforma friendly con todos los dispositivos(Tablet, PC, Smartphone) Actividad y cambios en tiempo real.',
    TITLE_MOCKUPS: 'VINCULABLE CON MAQUETAS',
    CONCEPT_MOCKUPS: 'Integración de tu inventario web con maquetas físicas, a través de Arduino para experiencia interactiva.',
    LABEL_NAME: 'Nombre',
    LABEL_EMAIL: 'Email',
    LABEL_PHONE: 'Número',
    LABEL_ABOUT: 'Cuéntanos sobre tu proyecto',
    MENU_PORTFOLIO: 'PORTAFOLIO',
    MENU_RETURN: 'REGRESAR',
    MENU_SOLUTIONS: 'SOLUCIONES',
    MENU_PROJECTS: 'PROYECTOS',
    MENU_INNOVATION: 'INNOVACIÓN',
    MENU_PROCESS: 'PROCESO',
    MENU_CONTACT:'CONTACTO',
    MENU_WORK: 'Trabaja con nosotros',
    ARCHITECTONIC: 'ARQUITECTONICO',
    PRODUCT: 'PRODUCTO',
    AUTOMOTIVE: 'AUTOMOTRIZ MÉDICO',
    CONCEPT: 'CONCEPTO',
    INDUSTRIAL: 'INDUSTRIAL',
    FURNITURE:'MOBILIARIO',
    ANIMATION: 'ANIMACIÓN 3D FILM',
    VIDEO_PRODUCTION: 'VIDEO PRODUCCIÓN',
    VIRTUAL_TOURS: 'RECORRIDOS VIRTUALES',
    VIDEO_PROMOTIONAL: 'VIDEO PROMOCIONAL',
    ANIMATION2D: 'ANIMACIÓN 2D',
    VIDEO_PRODUCT: 'VIDEO PRODUCTO',
    VIRTUAL_TOUR: 'RECORRIDO VIRTUAL',
    GAME_ON: 'GAME ON VR 360',
    MATTERPORT: 'MATTERPORT',
    WEBGL_TOUR:'RECORRIDO WEBGL',
    GRAPHIC_IDENTITY: 'IDENTIDAD GRÁFICA',
    BRANDING: 'BRANDING',
    APLICATIONS: 'APLICACIONES',
    SALE_BOOK: 'BOOK DE VENTA',
    DEVELOPMENT:' DISEÑO Y DESARROLLO',
    WEBSITE:'WEB A MEDIDA',
    SALES_SIMULATOR: 'SIMULADOR DE VENTAS',
    CHATBOT:'TEST FIT 360 CHAT BOT',
    FINANCIAL_SIMULATOR: 'SIMULADOR FINANCIERO',
    INVENTORY_CONNECTION: 'CONEXIÓN CON INVENTARIO',
    RESPONSIVE_DESIGN: 'DISEÑO RESPONSIVO',
    INTEGRATION:'INTEGRACIÓN',
    OURS:'DE TODAS NUESTRAS',
    TOOLS: 'HERRAMIENTAS',
    GENERATE: 'PARA GENERAR TU',
    YOUR_METAVERSE: 'METAVERSO',
    RENDERING_3D: 'Creación y visualización de imágenes hiperrealistas.',
    ANIMATION_3DFILM: 'Animación 3D y videoproducción.',
    VIRTUAL_REALITY:'Diseño y desarrollo de experiencia interactivas.',
    BRANDING2: 'Integración de herramientas para unificar la comunicación de ventas.',
    WEB_DEVELOPMENT: 'Generamos soluciones integrales e innovadoras.',
    METAVERSE: 'Presencia integral en el nuevo mundo virtual',
    SLOGAN_PROCESS: 'CÓMO LO HACEMOS REALIDAD',
    OUR_UNIVERSE: 'NUESTRO UNIVERSO',
    INTERACT:'Interactuamos en diversas industrias:',
    RESIDENTIAL: 'RESIDENCIAL',
    INDUSTRIAL_OFFICES: 'OFICINAS INDUSTRIALES',
    COMMERCIAL: 'COMERCIAL',
    ENTERTAINMENT: 'ENTRETENIMIENTO',
    URBAN_DEVELOPMENT: 'DESARROLLO URBANO',
    METHODOLOGY: 'METODOLOGÍA',
    CONTACT_US: 'CONTÁCTANOS',
    CONCEPT_CONTACTUS: 'Estamos disponibles para escucharte, ofrecemos atención personalizada por el medio que mejor se ajuste a tus necesidades.',
    TELL_US: 'CUÉNTANOS DE TU PROYECTO',
    CONCEPT_TELLUS: 'Compártenos la información que consideres que nos puede ser útil para entender tu proyecto y cuéntanos cómo te lo imaginas',
    ANALYSIS: 'ANÁLISIS Y PRESUPUESTO',
    CONCEPT_ANALYSIS: 'Nos aseguramos de entender tu proyecto para ofrecerte soluciones y las mejores herramientas para promoverlo.',
    WE_PLAN: 'PLANEAMOS',
    CONCEPT_WEPLAN: 'La experiencia como cliente es lo más importante para nosotros, nos aseguramos de que el proceso y los tiempos de respuesta sean satisfactorios.',
    WE_MAKE: 'LO HACEMOS REALIDAD',
    DISTANCE: 'Con nosotros la distancia es cosa del pasado.',
    CONCEPT_WORKTEAM: 'Somos un equipo multidisciplinario experimentado en proveer soluciones innovadoras a nuestros clientes.',
    HAVE_TRUSTEDUS: 'HAN CONFIADO EN NOSOTROS',
    RIGHTS: 'Todos los derechos reservados',
    PRIVACY: 'Política de privacidad'
}