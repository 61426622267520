import React from 'react';
import { Footers } from '../../common/styled';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation()
    return ( 
        <Footers>
            <img src="https://renderinc.mx/lp/assets/Logo.svg" alt="" />
            <div>
                <p>México | New York</p>
            </div>
            <p>© 2022 Renderinc® {t('RIGHTS')} | {t('PRIVACY')}</p>
        </Footers>
     );
}
 
export default Footer;