import React,{useEffect, useState} from 'react';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import View360 from '../Components/view360/view360';
import FormContacto from '../Components/FormContacto/FormContacto';
import Footer from '../Components/Footer/Footer';
import { ParallaxProvider,Parallax, ParallaxBanner } from 'react-scroll-parallax';
import YoutubeEmbed from '../Components/YoutubeEmbed/YoutubeEmbed';
import { Stiky } from '../common/styled';
import { useTranslation } from 'react-i18next';
const DetailProject = (props) => {
    let { project } = useParams();
    const { t } = useTranslation()
    const [hidden, setHidden] = useState('')

    useEffect(()=>{
        let windowWidth = window.innerWidth;
        let windowHeight = window.innerHeight;

        setTimeout(() => {
            setHidden("hidden")
          }, 2000);
      },[]);
    return ( 
        <div>
            <Stiky>
                <div className='whatsapp'>
                    <a target={'_blank'} href="https://api.whatsapp.com/send?phone=524431926495&text="><img src="/assets/whatsapp.svg" alt="" /></a>
                </div>
                <div className='contacto'>
                    <a href="#contacto">{t('MENU_CONTACT')}</a>
                </div>
            </Stiky>
            <div className={`content ${hidden}`}>
                <img src="/assets/Logo.svg" alt="" />
                {/* <h2 className="text_shadows">RENDERINC</h2> */}
            </div>
            <ParallaxProvider>
            {
                project === "nowus" &&
                <div>
                    <Portada>
                        <video muted autoPlay loop src="/assets/Video/portada-nowus.mp4"></video>
                        <img className='wow animate__animated animate__fadeIn' src="/assets/Nowus/svg-nowus-logo.svg" alt="" />
                        <lottie-player id="scroll-down" src="/assets/animacion_scrolling.json" background="transparent" speed="1" loop muted autoplay></lottie-player>
                    </Portada>
                    <Imagen padding>
                        <img className='wow animate__animated animate__fadeIn' src="/assets/Nowus/02-nowus.svg" alt="" />
                    </Imagen>
                    <Imagen padding>
                        <ParallaxBanner
                            layers={[{ image: '/assets/Nowus/01-nowus.jpg', speed: -15 }]}
                            className="image-parallax"
                        />
                        {/* <img src="/assets/Nowus/01-nowus.jpg" alt="" /> */}
                    </Imagen>
                    <Imagen padding>
                        <img src="/assets/Nowus/03-nowus.jpg" alt="" />
                    </Imagen>
                    <Imagen padding>
                        <img src="/assets/Nowus/04-nowus.jpg" alt="" />
                    </Imagen>
                    <Imagen padding>
                    <ParallaxBanner
                            layers={[{ image: '/assets/Nowus/05-nowus.jpg', speed: -15 }]}
                            className="image-parallax"
                        />
                        {/* <img src="/assets/Nowus/05-nowus.jpg" alt="" /> */}
                    </Imagen>
                    <View360 yaw={-90} pitch={0} urlImagen="/assets/Nowus/Lobby_nowus-360.jpg" idScene="nowus"></View360>
                    <Imagen padding heightAuto>
                        <video muted autoPlay loop src="/assets/Video/simulador-nowus.mp4"></video>
                    </Imagen>
                    <Doble>
                        <img className='wow animate__animated animate__fadeInLeft' src="/assets/Nowus/08-nowus.jpg" alt="" />
                        <img className='wow animate__animated animate__fadeInRight' src="/assets/Nowus/09-nowus.jpg" alt="" />
                    </Doble>
                    <Imagen padding>
                    <ParallaxBanner
                            layers={[{ image: '/assets/Nowus/10-nowus.jpg', speed: -15 }]}
                            className="image-parallax"
                        />
                        {/* <img src="/assets/Nowus/10-nowus.jpg" alt="" /> */}
                    </Imagen>
                    <Imagen padding heightAuto>
                        <img src="/assets/Nowus/11-nowus.jpg" alt="" />
                    </Imagen>
                    <Imagen padding>
                        <ParallaxBanner
                            layers={[{ image: '/assets/Nowus/12-nowus.jpg', speed: -15 }]}
                            className="image-parallax"
                        />
                        {/* <img src="/assets/Nowus/12-nowus.jpg" alt="" /> */}
                    </Imagen>
                    <Imagen padding>
                        <img src="/assets/Nowus/04-nowus.jpg" alt="" />
                    </Imagen>
                </div>
            }
            {
                project === "evamar" &&
                <div>
                    <Portada>
                        <video muted autoPlay loop src="/assets/Video/video-evamar-preview.mp4"></video>
                        <img className='width-static' src="/assets/Evamar/Logo_evamar.svg" alt="" />
                        <lottie-player id="scroll-down" src="/assets/animacion_scrolling.json" background="transparent" speed="1" loop muted autoplay></lottie-player>
                    </Portada>
                    <Imagen padding>
                        <img src="/assets/Evamar/evamar.01.svg" alt="" />
                    </Imagen>
                    <Imagen padding>
                        <ParallaxBanner
                            layers={[{ image: '/assets/Evamar/evamar.02.jpg', speed: -25 }]}
                            className="image-parallax"
                        />
                        {/* <img src="/assets/Evamar/evamar.02.jpg" alt="" /> */}
                    </Imagen>
                    <Imagen padding>
                        <img src="/assets/Evamar/evamar.03.jpg" alt="" />
                    </Imagen>
                    <Imagen padding>
                        <div className='videoWrapper'>
                            {/* <YoutubeEmbed idVideo={'YE892wpS6_Y'}/> */}
                            {/* <iframe width="560" height="315" title='evamar' frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  src="https://www.youtube.com/embed/YE892wpS6_Y?version=3&loop=1&controls=0&enablejsapi=1&showinfo=0&modestbranding=1&rel=0&mute=1&autoplay=1&playlist=YE892wpS6_Y&iv_load_policy=3&origin=https://renderinc.mx" allowFullScreen="1" /> */}
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/YE892wpS6_Y?version=3&controls=1&enablejsapi=1&showinfo=0&modestbranding=1&rel=0" ></iframe>
                        </div>
                    </Imagen>
                    <Imagen padding>
                        <ParallaxBanner
                            layers={[{ image: '/assets/Evamar/evamar.04.jpg', speed: -25 }]}
                            className="image-parallax"
                        />
                        {/* <img src="/assets/Evamar/evamar.04.jpg" alt="" /> */}
                    </Imagen>
                    <View360 yaw={-80} pitch={0} urlImagen="/assets/Evamar/alberca_yoga.jpg" idScene="evamar"></View360>
                    <Imagen padding heightAuto>
                        <img src="/assets/Evamar/evamar.07.jpg" alt="" />
                        <div className='sobrepuesto'>
                            <img className='wow animate__animated animate__fadeInLeft' src="/assets/Evamar/A_Post.png" alt="" />
                            <img className='wow animate__animated animate__fadeIn' src="/assets/Evamar/C_Post.png" alt="" />
                            <img className='wow animate__animated animate__fadeInRight' src="/assets/Evamar/D_Post.png" alt="" />
                        </div>
                        <div className='bottom-white'>

                        </div>
                    </Imagen>
                    <Imagen padding>
                        <img src="/assets/Evamar/evamar.09.jpg" alt="" />
                    </Imagen>
                    <Doble>
                        <img className='wow animate__animated animate__fadeInLeft' src="/assets/Evamar/evamar.10.jpg" alt="" />
                        <img className='wow animate__animated animate__fadeInRight' src="/assets/Evamar/evamar.11.jpg" alt="" />
                    </Doble>
                    <Imagen padding>
                        <ParallaxBanner
                            layers={[{ image: '/assets/Evamar/evamar.12.jpg', speed: -25 }]}
                            className="image-parallax"
                        />
                        {/* <img src="/assets/Evamar/evamar.12.jpg" alt="" /> */}
                    </Imagen>
                    <Imagen padding>
                        <img src="/assets/Evamar/evamar.13.jpg" alt="" />
                    </Imagen>
                </div>
            }
            {
                project === "velasur" &&
                <div>
                    <Portada>
                        <video muted autoPlay loop src="/assets/Video/portadavelasur.mp4"></video>
                        <img className='wow animate__animated animate__fadeIn' src="/assets/Velasur/logotipo-velasur.svg" alt="" />
                        <lottie-player id="scroll-down" src="/assets/animacion_scrolling.json" background="transparent" speed="1" loop muted autoplay></lottie-player>
                    </Portada>
                    <Imagen padding>
                        <img className='wow animate__animated animate__fadeIn' src="/assets/Velasur/iso-velasur.svg" alt="" />
                    </Imagen>
                    <Imagen padding heightAuto>
                        <img src="/assets/Velasur/web-velasur.jpg" alt="" />
                    </Imagen>
                    <Imagen padding>
                        <video muted autoPlay loop src="/assets/Video/simulador_velasur.mp4"></video>
                    </Imagen>
                    <Imagen padding >
                        <video muted autoPlay loop src="/assets/Video/loop_velasur.mp4"></video>
                    </Imagen>
                </div>
            }
            <Linea></Linea>
            <FormContacto></FormContacto>
            <Footer></Footer>
            </ParallaxProvider>
        </div>
     );
}

const Linea = styled.div`
    width: 100%;
    height: 20px;
    background-color: white;
`
const Portada = styled.div`
    background-color: black;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    img{
        position: absolute;
        width: auto;
        @media (max-width:550px) {
            width: 200px;
        }
    }
    .width-static{
        width: 500px;
        @media (max-width:550px) {
            width: 250px;
        }
    }
    #scroll-down{
        width: 140px;
        height: 140px;
        position: absolute;
        bottom: 0;
        @media (max-width: 550px) {
            width: 100px;
            height: 100px;
        }
    }
`
const Imagen = styled.div`
    position: relative;
    width: 100%;
    height: ${props => props.heightAuto ? "auto" : "850px"};
    padding: ${props => props.padding ? "20px 20px 0 20px" : "0"};
    @media (max-width:1024px) {
        height: auto;
    }
    @media (max-width:550px) {
        padding: ${props => props.padding ? "10px 10px 0 10px" : "0"};
        height: ${props => props.heightAuto ? "auto" : "250px"};
    }
    img,video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .videoWrapper {
        /* position: relative;
        padding-bottom: 56.25%;
        padding-top: 25px;
        height: 0; */
        /* position: relative;
        top: 0;
        left: 0; */
        width: 100%;
        height: 100%;
        /* z-index: 0;
        overflow: hidden;
        pointer-events: none; */
        iframe{
            /* position: absolute;
            inset: -86.7188px 0px 0px;
            /* width: 2031px;
            height: 1142.44px; 
            min-width: 100%;
            min-height: 100%;
            border-width: 0; */
        }
    }
    .height-auto{
        height: auto;
    }
    .sobrepuesto{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-evenly;
        position: absolute;
        left: 0;
        bottom: 80px;
        @media (max-width:550px) {
            bottom: 30px;
            overflow-x: hidden;
        }
        img{
            width: 30%;
            @media (max-width:550px) {
               width: 60%;
               &:last-child{
                position: absolute;
               }
            }
        }
    }
    .bottom-white{
        height: 300px;
        width: 100%;
        background-color: white;
    }
`
const Doble = styled.div`
    display: flex;
    padding: 0 10px;
    @media (max-width:550px) {
        height: 200px;
    }
    img{
        width: 50%;
        height: 100%;
        padding: 10px 10px 0 5px;
        @media (max-width:550px) {
           object-fit: cover;
        }
    }
`
 
export default DetailProject;