import React, {useState,useCallback,useEffect} from 'react';
import { Headers } from '../../common/styled';
import './Header.scss'
import * as Scroll from "react-scroll";
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Link, useNavigate, useLocation , redirect} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Header = (props) => {
    const { t, i18n } = useTranslation()
    const [y, setY] = useState(window.scrollY);
    const [scrollMenu, setScroll] = useState('')
    const navigate = useNavigate();
    // const [url, setUrl] = useState(useLocation())
    const location = useLocation()
    //console.log('Location: ', location)

    const handleMenu = (type) =>{
        props.setOpen(props.open === 'menu-open' ? '' : 'menu-open')
        if (type) scrollToPage(type)
    }

    const handleNavigation = useCallback(
        (e) => {
          const window = e.currentTarget;
          if (y > window.scrollY) {
              if(window.scrollY < 500){
                  setScroll('')
             }
          } else if (y < window.scrollY) {
              if(window.scrollY > 500){
                  setScroll('back_menu')
              }
          }
          setY(window.scrollY);
        },
        [y]
    );
    
    const scrollTarget = (target) => { 
        scroller.scrollTo(target, {smooth: true, duration: 700})
    };

    const scrollToPage = async (target) => {
        //console.log('target: ', target)
        if(location.pathname === "/proceso" || location.pathname === "/detalle/evamar" || location.pathname === "/detalle/nowus" || location.pathname === "/detalle/velasur"){
            //console.log("path entre al if", location)
            navigate(`/${target}`)
        }else{
            //console.log('entre al else')
            navigate(target)
            scrollTarget(target.slice(1));
        }
    };

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
    
        return () => {
          window.removeEventListener("scroll", handleNavigation);
        };
      }, [handleNavigation]);

      const handleSpanish = () => {
        i18n.changeLanguage('es')
        document.getElementById('eng').classList.remove('activo')
        document.getElementById('spa').classList.add('activo')
        document.getElementById('eng-movil').classList.remove('activo')
        document.getElementById('spa-movil').classList.add('activo')
        const virtual = document.getElementById('change_eng');
        virtual.classList.remove('eng')

      }

      const handleEng = () => {
        i18n.changeLanguage('en')
        document.getElementById('spa').classList.remove('activo')
        document.getElementById('eng').classList.add('activo')
        document.getElementById('spa-movil').classList.remove('activo')
        document.getElementById('eng-movil').classList.add('activo')
        const virtual = document.getElementById('change_eng');
        virtual.classList.add('eng')
      }
    return (
        <>
        <Headers className={scrollMenu}>
            <div className='logo'>
                <Link to="/"><img src="/assets/Logo.svg" alt="" /></Link>
            </div>
            <div className='socials'>
                <div className='idioma'>
                    <button id='spa' className='activo' onClick={handleSpanish}>SPA</button>
                    <button id='eng' onClick={handleEng}>ENG</button>
                </div>
                <div className='portafolio'>
                    <a href="https://renderinc.mx/portafolio">{t('MENU_PORTFOLIO')} </a>
                </div>
                <div className='icon_menu'>
                    <p className="btn-toggle" onClick={()=> handleMenu()} ><span></span></p>
                </div>
            </div>
        </Headers>
            <nav>
                <ul>
                    <div className='icono_r'>
                        <Link to="/" onClick={()=> handleMenu()}><p>R</p></Link>
                    </div>
                    <li>
                        <a onClick={()=> {handleMenu("#soluciones")}}>{t('MENU_SOLUTIONS')}</a>
                    </li>
                    <li>
                        <a onClick={()=> handleMenu("#proyectos")}>{t('MENU_PROJECTS')}</a>
                    </li>
                    <li>
                        <a onClick={()=> handleMenu("#innovacion")}>{t('MENU_INNOVATION')}</a>
                    </li>
                    <li>
                        <Link to={'/proceso'} onClick={()=> handleMenu()}>{t('MENU_PROCESS')}</Link>
                    </li>
                    <li>
                        <a onClick={()=> handleMenu("#contacto")}>{t('MENU_CONTACT')}</a>
                    </li>

                    <div className='social'>
                        <a target={'_blank'} href="https://www.instagram.com/renderinc.mx/"><img src="/assets/instagram.png" alt="" /></a>
                        <a  target={'_blank'} href="https://www.facebook.com/renderinc.mx/"><img src="/assets/facebook.png" alt="" /></a>
                        <a   target={'_blank'} href="https://www.behance.net/renderincmx"><img src="/assets/behance.png" alt="" /></a>
                    </div>
                    <div className='idioma-movil'>
                        <button id='spa-movil' className='activo' onClick={handleSpanish}>SPA</button>
                        <button id='eng-movil' onClick={handleEng}>ENG</button>
                    </div>
                    <p>{t('MENU_WORK')}</p>
                </ul>
            </nav>
        </>
     );
}

 
export default Header;