import React, {useState, useEffect} from 'react';
import { Contacto } from '../../common/styled';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'
import swal from 'sweetalert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '../../common/styled';
import { useTranslation } from 'react-i18next';

const FormContacto = () => {
    const { t } = useTranslation()
    const [recaptcha, setRecaptcha] = useState("")
    const [errorRecaptcha, showError] = useState("ocultar-error")
    const onChange = (val) => {
        setRecaptcha(val)
    }
    const formik = useFormik({
        initialValues: {
          nombre: '',
          tel: '',
          email: '',
          about: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required('Campo requerido'),
            tel: Yup.string().min(10,'Deben ser mínimo 10 dígitos').max(10,'Deben ser máximo 10 dígitos').required('Campo requerido'),
            email: Yup.string().email('Correo inválido'),
            about: Yup.string()
        }),
        onSubmit: async values => {
            const payload = {
                ...values,
                recaptcha: recaptcha 
            }
            
            if(recaptcha !== ''){
                const res = await axios.post(process.env.REACT_APP_FORM_CONTACT_API, payload)
                if(res.data.success){
                    swal("Enviado", "El correo ha sido enviado correctamente", "success");
                    formik.resetForm();
                }else{
                    if(res.data.error){
                        swal("Error", "Ocurrió un problema al enviar el correo", "error");
                        formik.resetForm();
                    }
                }
            }else{
                showError('error')
            }
        },
    });
    return ( 
        <Contacto id='contacto'>
        <h1 className='wow animate__animated animate__fadeInDown'>LET'S <br/> WORK</h1>
        <div className='formulario'>
            <form onSubmit={formik.handleSubmit} method={"POST"}>
                <input type="text" placeholder={t('LABEL_NAME')} 
                            id="nombre"
                            name="nombre"                            
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nombre}
                />
                {formik.touched.nombre && formik.errors.nombre ? (
                <div>{formik.errors.nombre}</div>
                ) : null}
                <input placeholder={t('LABEL_EMAIL')} 
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                    <div>{formik.errors.email}</div>
                ) : null}
                <input type="text"  placeholder={t('LABEL_PHONE')} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tel}      
                    id="tel"
                    name="tel"                      
                />
                {formik.touched.tel && formik.errors.tel ? (
                    <div>{formik.errors.tel}</div>
                ) : null}
                <input type="text" placeholder={t('LABEL_ABOUT')} 
                    id="about"
                    name="about"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.about}                                
                />
                {formik.touched.about && formik.errors.about ? (
                    <div>{formik.errors.about}</div>
                ) : null}
                <div className='recaptcha'>
                    <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onChange}
                    />
                </div>
                <p className={`${errorRecaptcha}`}>Llene el recaptcha</p>
                <Button primary className='recaptcha'>
                    <button type="submit">{t('BTN_DATA')} </button>
                </Button>
            </form>
        </div>
    </Contacto>
     ); 
}
 
export default FormContacto;