import React,{useEffect, useState} from 'react';
import { Stiky, Hero, Concepto, Proceso, Clientes, Button, Contacto } from '../common/styled';
import { ParallaxProvider,Parallax, ParallaxBanner } from 'react-scroll-parallax';
import FormContacto from '../Components/FormContacto/FormContacto';
import Footer from '../Components/Footer/Footer';
import { useLocation, useNavigate } from "react-router-dom";
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { useTranslation } from 'react-i18next';

const Process = () => {
    const { t } = useTranslation()
    const [hidden, setHidden] = useState('')
    const actualUrl = useLocation();
    const history = useNavigate();

    const scrollToPage = async (target) => {
        history(target)
        //scrollTarget(target.slice(1));
    };
    useEffect(()=>{
        scrollToPage(actualUrl.hash)
        setTimeout(() => {
            setHidden("hidden")
          }, 2000);
      },[]);
    return ( 
        <div>
            <div className={`content ${hidden}`}>
                <img src="/assets/Logo.svg" alt="" />
            </div>
            <Stiky>
                <div className='whatsapp'>
                    <a target={'_blank'} href="https://api.whatsapp.com/send?phone=524431926495&text="><img src="/assets/whatsapp.svg" alt="" /></a>
                </div>
                <div className='contacto'>
                    <a href="#contacto">{t('MENU_CONTACT')}</a>
                </div>
            </Stiky>
            <ParallaxProvider>
                <Hero>
                    {/* <div className='fondo'></div> */}
                    <lottie-player id="scroll-down" src="/assets/animacion_scrolling.json" background="transparent" speed="1" loop muted autoplay></lottie-player>
                    <div className='over'>
                    <Parallax   
                        speed={-20}>
                            <h1 className='wow animate__animated animate__fadeInDown process_title'>GO FUTHER</h1>
                            <h2>{t('SLOGAN_PROCESS')}</h2>
                            <p>{t('CONCEPT_RENDERINC')}</p>
                    </Parallax>
                        </div>
                    <img src="/assets/portada-proceso.jpg" alt="" />
                    {/* <video  muted={true} autoPlay={true} loop src="/assets/Video/hero.mp4"></video> */}
                </Hero>
                <Concepto>
                    <div className='left'>
                        <h2 className='wow animate__animated animate__fadeInDown'>{t('OUR_UNIVERSE')}</h2>
                        <p className='wow animate__animated animate__fadeIn'>{t('INTERACT')}</p>
                    </div>
                    <div className='right'>
                        <h2 className='wow animate__animated animate__fadeInRight'>{t('RESIDENTIAL')}</h2>
                        <h3 className='wow animate__animated animate__fadeInRight'>{t('INDUSTRIAL_OFFICES')}</h3>
                        <h1 className='wow animate__animated animate__fadeInRight'>{t('COMMERCIAL')}</h1>
                        <h4 className='wow animate__animated animate__fadeInRight'>{t('ENTERTAINMENT')}</h4>
                        <h5 className='wow animate__animated animate__fadeInRight'>{t('URBAN_DEVELOPMENT')}</h5>
                    </div>
                </Concepto>
                <Proceso>
                        <h1 className='wow animate__animated animate__fadeInDown'>{t('METHODOLOGY')}</h1>
                        <div className='content_arrow'>
                            <div className='arrow'>
                                <Parallax   
                                    speed={-10}  translateY= {[-100, 40]}>
                                        <img  src="/assets/Svg/arrow.svg" alt="" />
                                </Parallax>
                            </div>
                        </div>
                        <div className='proceso'>
                            <div className='card'>
                                <div className='texto'>
                                    <h4>{t('CONTACT_US')}</h4>
                                    <p>{t('CONCEPT_CONTACTUS')}</p>
                                </div>
                                <img className='wow animate__animated animate__fadeInDown' src="/assets/Svg/1.svg" alt="" />
                            </div>
                            <div className='card'>
                                <img className='wow animate__animated animate__fadeInDown' src="/assets/Svg/2.svg" alt="" />
                                <div className='texto'>
                                    <h4>{t('TELL_US')}</h4>
                                    <p>{t('CONCEPT_TELLUS')}</p>
                                </div>
                                
                            </div>
                            <div className='card'>
                                <div className='texto'>
                                    <h4>{t('ANALYSIS')}</h4>
                                    <p>{t('CONCEPT_ANALYSIS')}</p>
                                </div>
                                <img className='wow animate__animated animate__fadeInDown' src="/assets/Svg/3.svg" alt="" />
                            </div>
                            <div className='card'>
                                <img className='wow animate__animated animate__fadeInDown' src="/assets/Svg/4.svg" alt="" />
                                <div className='texto'>
                                    <h4>{t('WE_PLAN')}</h4>
                                    <p>{t('CONCEPT_WEPLAN')}</p>
                                </div>
                            </div>
                        </div>
                </Proceso>
                <Clientes>
                        <div className='clientes_top'>
                            <h1 className='wow animate__animated animate__fadeIn'>{t('WE_MAKE')}</h1>
                            <div className='herramientas_virtual'>
                                <img src="/assets/Clientes/svg-review-studio.svg" alt="" />
                                <img src="/assets/Clientes/svg-google-meet.svg" alt="" />
                                <img src="/assets/Clientes/svg-whatsapp.svg" alt="" />
                                <img src="/assets/Clientes/svg-discord.svg" alt="" />
                            </div>
                            <p>{t('DISTANCE')}</p>
                            <Button>
                                <a href="#contacto">{t('BTN_SCHEDULE')}</a>
                            </Button>
                        </div>
                        <div className='work_team'>
                            <div className='left'>
                                <h2 className='wow animate__animated animate__fadeInDown' >WORK TEAM</h2>
                                <p>{t('CONCEPT_WORKTEAM')}</p>
                            </div>
                            <div className='right'>
                                <img src="./assets/team.jpg" alt="" />
                            </div>
                        </div>
                        <div className='clientes'>
                            <h3 className='wow animate__animated animate__fadeInDown'>{t('HAVE_TRUSTEDUS')}</h3>
                            <div className='logos-clientes'>
                                <img src="/assets/clientes2/svg-core-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-3dw-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-vreal-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-datum-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-cinepolis-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-4s-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-pop-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-grupo-arpada-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-don-porfirio-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-padua-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-bbr-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-pinar-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-construreal-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-mexico-plaza-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-logo-grupo-velas.svg" alt="" />
                                <img src="/assets/clientes2/svg-tarsus-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-san-miguelito-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-orange-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-ruba-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-condocasa-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-investi-renderinc.svg" alt="" />  
                                <img src="/assets/clientes2/svg-alexi-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-deesa-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-basico-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-urbn-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-loma-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-altosano-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-colliers-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-wollman-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-slo-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-fraterna-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-acostaverde-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-paradigm-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-copri-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-legorreta-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-pro-urbano-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-starmedica-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-segovia-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-reti-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-comebi-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-tre-marias-renderinc.svg" alt="" />
                                <img src="/assets/clientes2/svg-hit-renderinc.svg" alt="" />
                            </div>
                        </div>
                </Clientes>
                <FormContacto></FormContacto>
                <Footer></Footer>
            </ParallaxProvider>
        </div>
     );
}
 
export default Process;