import React, {useState, useEffect} from 'react';
import { ParallaxProvider,Parallax, ParallaxBanner } from 'react-scroll-parallax';
import FormContacto from '../Components/FormContacto/FormContacto';
import WOW from "wowjs"
import 'animate.css'
import Slider from '../Components/Slider/Slider';
import { SwiperSlide } from "swiper/react";
import { Stiky, Hero, Video, About, Proyectos, Simulador, Button } from '../common/styled';
import Footer from '../Components/Footer/Footer';
import { CSSTransition } from "react-transition-group";
import {Link, useLocation, useNavigate } from "react-router-dom";
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { useTranslation } from 'react-i18next';
const Home = () => {
    const [hidden, setHidden] = useState('')
    const { t, i18n } = useTranslation()
    const [hide_left, setHideLeft] = useState('')
    const [type_Service, setTypeService] = useState({})
    const [close, setClose] = useState('')
    const [showPause, setPause] = useState(false)
    const [tresD, showTresD] = useState(false)
    const [virtual, showVirtual] = useState(false)
    const [branding, showBranding] = useState(false)
    const [film, showFilm] = useState(false)
    const [web, showWeb] = useState(false)
    const [metaveros, showMetaveros] = useState(false)
    const actualUrl = useLocation();
    const conceptos = {
        tresD: '3d',
        filmAnimation: 'film',
        virtual: 'virtual',
        branding: 'branding',
        web: 'web',
        metaverso: 'metaverso'
    }

    const handleAnimation = (type) =>{
        setHideLeft('hide_left')
        setTypeService(type)
        setClose('close')
        const others = document.getElementsByClassName('iconos')
        for (let index = 0; index < others.length; index++) {
            if(others[index].id !== type){
                // console.log(others[index])
                others[index].classList.add("hide_right");
            }else{
                const typeID = document.getElementById(type);
                typeID.classList.add("enlarge_left")
            }
        }
        switch(type) {
            case '3d':
                showTresD(true)
                break;
            case 'film':
                showFilm(true)
                break;
            case 'virtual':
                showVirtual(true)
                break;
            case 'branding':
                showBranding(true)
                break;
            case 'web':
                showWeb(true)
                break;
            case 'metaverso':
                showMetaveros(true)
                break;
            default:
                break;
              
        }
    }
    const handleCloseAnimation = (type) =>{
        setHideLeft('')
        setTypeService({})
        const others = document.getElementsByClassName('iconos')
        for (let index = 0; index < others.length; index++) {
            others[index].classList.remove("hide_right");
            others[index].classList.remove("enlarge_left");
        }
        switch(type) {
            case '3d':
                showTresD(false)
                break;
            case 'film':
                showFilm(false)
                break;
            case 'virtual':
                showVirtual(false)
                break;
            case 'branding':
                showBranding(false)
                break;
            case 'web':
                showWeb(false)
                break;
            case 'metaverso':
                showMetaveros(false)
                break;
            default:
                break;
              
        }
    }

    const handleReproducir = () => {
        var video=document.getElementById('video'); 
        setPause(true)
        video.play(); 
    }

    const handlePause = () => {
        var video=document.getElementById('video'); 
        setPause(false)
        video.pause(); 
    }
    const history = useNavigate();
    const scrollTarget = (target) => { 
        scroller.scrollTo(target, {smooth: true, duration: 700})
    };
  
    const scrollToPage = async (target) => {
        history(target)
        scrollTarget(target.slice(1));
    };

      useEffect(()=>{
        scrollToPage(actualUrl.hash)
        setTimeout(() => {
            setHidden("hidden")
          }, 2000);
        new WOW.WOW({
          live:false
        }).init();
      },[]);
    return ( 
        <>
            <div className={`content ${hidden}`}>
                <img src="/assets/Logo.svg" alt="" />
                {/* <h2 className="text_shadows">RENDERINC</h2> */}
            </div>
            <ParallaxProvider>
                <Stiky>
                    <div className='whatsapp'>
                        <a target={'_blank'} href="https://api.whatsapp.com/send?phone=524431926495&text=">
                            <img src="/assets/whatsapp.svg" alt="" /></a>
                    </div>
                    <div className='contacto'>
                        <a href="#contacto">{t('MENU_CONTACT')}</a>
                    </div>
                </Stiky>
                <Hero>
                <lottie-player id="scroll-down" src="/assets/animacion_scrolling.json" background="transparent" speed="1" loop muted autoplay></lottie-player>
                    <div className='over'>
                    <Parallax   
                    speed={-10}>
                        <h1 className='wow animate__animated animate__fadeInDown'>FULL SERVICE AGENCY</h1>
                        <h2>BRINGING REALITY CLOSER</h2>
                        <p>{t('CONCEPT_RENDERINC')}</p>
                        <Button  primary>
                            <a href="#soluciones">{t('BTN_SOLUTIONS')}</a>
                        </Button>
                    </Parallax>
                    </div>
                    <video  muted={true} autoPlay={true} loop src="/assets/Video/hero.mp4"></video>
                </Hero>
                <Video>
                    <video loop muted autoPlay id="video" src="/assets/Video/renderinc-full.mp4"></video>
                    {/* {
                        !showPause &&
                        <a onClick={() => handleReproducir()}><img src="/assets/Video/svg-play-renderinc.svg" alt="" /></a>
                    }
                                       {
                        showPause &&
                        <a onClick={() => handlePause()}><img src="/assets/Video/video-pause-button.png" alt="" /></a>
                    } */}
                    <Button className='button_video'>
                        <a href="/portafolio">{t('BTN_PORTFOLIO')}</a>
                    </Button>
                </Video>
                <About id='soluciones'>
                    <video muted={true} autoPlay={true} loop src="/assets/Video/Soluciones.mp4"></video>
                    <div id="change_eng" className='about'>
                        <div className={`titulo_about ${hide_left}`}>
                            <h1 className='wow animate__animated animate__fadeInDown'>{t('TITLE_SOLUTIONS')}</h1>
                            <p>{t('CONCEPT_SOLUTIONS')}</p>
                        </div>
                        <CSSTransition
                            in={tresD}
                            timeout={200}
                            classNames="show"
                            unmountOnExit
                        >
                            <div className='showDetails'>
                                <p className={`${close}`} onClick={()=>handleCloseAnimation("3d")}>X</p>
                               <div className='text-details'>
                                <h3>{t('ARCHITECTONIC')}</h3>
                                <h2>{t('PRODUCT')}</h2>
                                <h4>{t('AUTOMOTIVE')}</h4>
                                <h2>{t('CONCEPT')}</h2>
                                <h1>{t('INDUSTRIAL')}</h1>
                                <h1>{t('FURNITURE')}</h1>
                               </div>
                            </div>
                        </CSSTransition>
                        <CSSTransition
                            in={film}
                            timeout={200}
                            classNames="show"
                            unmountOnExit
                        >
                            <div className='showDetails '>
                                <p className={`${close}`} onClick={()=>handleCloseAnimation("film")}>X</p>
                               <div className='text-details film'>
                                <h4>{t('ANIMATION')}</h4>
                                <h4>{t('VIDEO_PRODUCTION')}</h4>
                                <h6>{t('VIRTUAL_TOURS')}</h6>
                                <h5>{t('VIDEO_PROMOTIONAL')}</h5>
                                <h2>{t('ANIMATION2D')}</h2>
                                <h3>{t('VIDEO_PRODUCT')}</h3>
                               </div>
                            </div>
                        </CSSTransition>
                        <CSSTransition
                            in={virtual}
                            timeout={200}
                            classNames="show"
                            unmountOnExit
                        >
                            <div className='showDetails'>
                                <p className={`${close}`} onClick={()=>handleCloseAnimation("virtual")}>X</p>
                               <div className='text-details virtual '>
                                <h4>{t('VIRTUAL_TOUR')}</h4>
                                <h3>{t('GAME_ON')}</h3>
                                <h5>{t('MATTERPORT')}</h5>
                                <h2>{t('WEBGL_TOUR')}</h2>
                               </div>
                            </div>
                        </CSSTransition>
                        <CSSTransition
                            in={branding}
                            timeout={200}
                            classNames="show"
                            unmountOnExit
                        >
                            <div className='showDetails'>
                                <p className={`${close}`} onClick={()=>handleCloseAnimation("branding")}>X</p>
                               <div className='text-details branding'>
                                <h4>{t('GRAPHIC_IDENTITY')}</h4>
                                <h2>{t('BRANDING')}</h2>
                                <h3>{t('APLICATIONS')}</h3>
                                <h5>{t('SALE_BOOK')}</h5>
                               </div>
                            </div>                           
                        </CSSTransition>
                        <CSSTransition
                            in={web}
                            timeout={200}
                            classNames="show"
                            unmountOnExit
                        >
                            <div className='showDetails'>
                                <p className={`${close}`} onClick={()=>handleCloseAnimation("web")}>X</p>
                               <div className='text-details web'>
                                <h4>{t('DEVELOPMENT')}</h4>
                                <h3>{t('WEBSITE')}</h3>
                                <h1>{t('SALES_SIMULATOR')}</h1>
                                <h4>{t('CHATBOT')}</h4>
                                <h5>{t('FINANCIAL_SIMULATOR')}</h5>
                                <h6>{t('INVENTORY_CONNECTION')}</h6>
                                <h2>{t('RESPONSIVE_DESIGN')}</h2>

                               </div>
                            </div>                    
                        </CSSTransition>
                        <CSSTransition
                            in={metaveros}
                            timeout={200}
                            classNames="show"
                            unmountOnExit
                        >
                            <div className='showDetails'>
                                <p className={`${close}`} onClick={()=>handleCloseAnimation("metaverso")}>X</p>
                               <div className='text-details meta'>
                                <h6>{t('INTEGRATION')}</h6>
                                <h2>{t('OURS')}</h2>
                                <h3>{t('TOOLS')}</h3>
                                <h4>{t('GENERATE')}</h4>
                                <h1>{t('YOUR_METAVERSE')}</h1>
                               </div>
                            </div>
                        </CSSTransition>
                        <div className='servicios'>
                            <div id='3d' className='iconos wow animate__animated animate__fadeIn' onClick={()=>handleAnimation('3d')}>
                                <img className='wow animate__animated animate__fadeInDown' src="/assets/Svg/svg-3drenderinc.svg" alt="" />
                                <div className='text-serv'>
                                    <h3>3D <br/> RENDERING</h3>
                                </div>
                            </div>
                            <div id='film' className='iconos wow animate__animated animate__fadeIn' onClick={()=>handleAnimation('film')}>
                                <img className='wow animate__animated animate__fadeInDown' src="/assets/Svg/svg-3dfilm.svg" alt="" />
                                <div className='text-serv'>
                                    <h3>3D FILM <br/> & ANIMATION</h3>
                                </div>
                            </div>
                            <div id='virtual' className='iconos wow animate__animated animate__fadeIn' onClick={()=>handleAnimation('virtual')}>
                                <img className='wow animate__animated animate__fadeInDown' src="/assets/Svg/svg-virtual-reality.svg" alt="" />
                                <div className='text-serv'>
                                    <h3>VIRTUAL <br/> REALITY</h3>
                                </div>
                            </div>
                            <div id='branding' className='iconos wow animate__animated animate__fadeIn' onClick={()=>handleAnimation('branding')}>
                                <img className='wow animate__animated animate__fadeInDown' src="/assets/Svg/svg-real-estate-branding.svg" alt="" />
                                <div className='text-serv'>
                                    <h3>REAL ESTATE <br/> BRANDING</h3>
                                </div>
                            </div>
                            <div id='web' className='iconos wow animate__animated animate__fadeIn' onClick={()=>handleAnimation('web')}>
                                <img className='wow animate__animated animate__fadeInDown' src="/assets/Svg/svg-web-renderinc.svg" alt="" />
                                <div className='text-serv'>
                                    <h3>WEB <br/> DEVELOPMENT</h3>
                                </div>
                            </div>
                            <div id='metaverso' className='iconos wow animate__animated animate__fadeIn' onClick={()=>handleAnimation('metaverso')}>
                                <img className='wow animate__animated animate__fadeInDown' src="/assets/Soluciones/svg-megtaverso-renderinc.svg" alt="" />
                                <div className='text-serv'>
                                    <h3>METAVERSE</h3>
                                </div>
                            </div>
                        </div>
                        {
                            type_Service === conceptos.tresD &&
                            <div className='showConcepto'>
                                <p>{t('RENDERING_3D')}</p>
                                <Button  primary>
                                    <a href="#contacto">{t('BTN_SCHEDULE')}</a>
                                </Button>
                            </div>
                        }
                                                {
                            type_Service === conceptos.filmAnimation &&
                            <div className='showConcepto'>
                                <p>{t('ANIMATION_3DFILM')}</p>
                                <Button  primary>
                                    <a href="#contacto">{t('BTN_SCHEDULE')}</a>
                                </Button>
                            </div>
                        }
                                                {
                            type_Service === conceptos.virtual &&
                            <div className='showConcepto'>
                                <p>{t('VIRTUAL_REALITY')}</p>
                                <Button  primary>
                                    <a href="#contacto">{t('BTN_SCHEDULE')}</a>
                                </Button>
                            </div>
                        }
                                                {
                            type_Service === conceptos.branding &&
                            <div className='showConcepto'>
                                <p>{t('BRANDING2')}</p>
                                <Button  primary>
                                    <a href="#contacto">{t('BTN_SCHEDULE')}</a>
                                </Button>
                            </div>
                        }
                        {
                            type_Service === conceptos.web &&
                            <div className='showConcepto'>
                                <p>{t('WEB_DEVELOPMENT')}</p>
                                <Button  primary>
                                    <a href="#contacto">{t('BTN_SCHEDULE')}</a>
                                </Button>
                            </div>
                        }
                                                {
                            type_Service === conceptos.metaverso &&
                            <div className='showConcepto metaverso'>
                                <p>{t('METAVERSE')}</p>
                                <Button  primary>
                                    <a href="#contacto">{t('BTN_SCHEDULE')}</a>
                                </Button>
                            </div>
                        }
                    </div>
                </About>
                <Proyectos id='proyectos'>
                <Slider nav={false} >
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <video muted={true} autoPlay={true} loop src="/assets/Video/back_nowus.mp4"></video>
                            <div className='slider_text'>
                                <h1 className='wow animate__animated animate__fadeInDown'>{t('TITLE_NOWUS')}</h1>
                                <h3>{t('KEY_WORDS_NOWUS')}</h3>
                                <p>{t('CONCEPT_NOWUS')}</p>
                                <Button primary>
                                    <Link to="/detalle/nowus">{t('BTN_PROYECTO')}</Link>
                                </Button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <video muted={true} autoPlay={true} loop src="/assets/Video/Evamar.mp4"></video>
                            <div className='slider_text'>
                                <h1>{t('TITLE_EVAMAR')}</h1>
                                <h3 className='green'>{t('KEY_WORDS_EVAMAR')}</h3>
                                <p>{t('CONCEPT_EVAMAR')}</p>
                                <Button primary>
                                    <Link to="/detalle/evamar">{t('BTN_PROYECTO')}</Link>
                                </Button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <video muted={true} autoPlay={true} loop src="/assets/Video/back-velasur.mp4"></video>
                            <div className='slider_text'>
                                <h1>{t('TITLE_VELASUR')}</h1>
                                <h3 className='green'>{t('KEY_WORDS_VELASUR')}</h3>
                                <p>{t('CONCEPT_VELASUR')}</p>
                                <Button primary>
                                    <Link to="/detalle/velasur">{t('BTN_PROYECTO')}</Link>
                                </Button>
                            </div>
                        </div>
                    </SwiperSlide>
                </Slider>
                </Proyectos>
                <Simulador  id='innovacion'>
                    <video muted={true} autoPlay={true} loop src="/assets/Video/Back-simulador.mp4"></video>
                    <div className='left'>
                        <h1 className='wow animate__animated animate__fadeInDown'>{t('TITLE_SIMULADOR')}</h1>
                        <p>{t('CONCEPT_SIMULADOR')}</p>
                        <Button primary>
                            <a href="#contacto">{t('BTN_SCHEDULE')}</a>
                        </Button>
                        <div className='demos_simu'>
                            <p>DEMOS:</p>
                            <div>
                                <a href="https://nowus.mx/disponibilidad"><img src="/assets/Simulador/svg-nowus.svg" alt="" /></a>
                                <a href="http://laslomashabitat.mx/disponibilidad"><img src="/assets/Simulador/svg-las-lomas.svg" alt="" /></a>
                                <a href="https://laceibabosque.com/disponibilidad"><img src="/assets/Simulador/svg-la-ceiba.svg" alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className='linea-vertical'></div>
                    <div className='right'>
                        <ul>
                            <li>
                                <div  className='detalles_row'>
                                    <div className='imagen_flecha'></div><h4>01</h4><h4 className='title'>{t('TITLE_LEADS')}</h4>
                                </div>
                                <p className='visibility_p'>{t('CONCEPT_LEADS')}</p>    
                            </li>
                            <li>
                                <div className='detalles_row'>
                                    <div className='imagen_flecha'></div><h4>02</h4><h4 className='title'>{t('TITLE_CRM')}</h4>
                                </div>
                                <p className='visibility_p'>{t('CONCEPT_CRM')}</p>    
                            </li>
                            <li>
                            <div className='detalles_row'>
                                <div className='imagen_flecha'></div><h4>03</h4><h4 className='title'>{t('TITLE_PAY')}</h4>
                            </div>
                            <p className='visibility_p'>{t('CONCEPT_PAY')}</p>
                            </li>
                            <li>
                            <div className='detalles_row'>
                                <div className='imagen_flecha'></div><h4>04</h4><h4 className='title'>{t('TITLE_ADMIN')}</h4>
                            </div>
                            <p className='visibility_p'>{t('CONCEPT_ADMIN')}</p>
                            </li>
                            <li>
                            <div className='detalles_row'>
                                <div className='imagen_flecha'></div><h4>05</h4><h4 className='title'>{t('TITLE_RESPONSIVE')}</h4>
                            </div>
                            <p className='visibility_p'>{t('CONCEPT_RESPONSIVE')}</p>
                            </li>
                            <li>
                            <div className='detalles_row'>
                                <div className='imagen_flecha'></div><h4>06</h4><h4 className='title'>{t('TITLE_MOCKUPS')}</h4>
                            </div>
                            <p className='visibility_p'>{t('CONCEPT_MOCKUPS')}</p>
                            </li>
                        </ul>
                    </div>
                </Simulador>
                <FormContacto></FormContacto>
                <Footer></Footer>
            </ParallaxProvider>
        </>
     );
}

export default Home;