import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const Stiky = styled.div`
    width: 180px;
    height: 50px;
    display: flex;
    position: fixed;
    justify-content: space-between;
    z-index: 99;
    right: -65px;
    top: 80%;
    transform: rotate(270deg);
    @media (max-width:1024px) {
        top: 85%;
    }
    @media (max-width:550px) {
        height: 40px;
        right: -70px;
        top: 80%;
    }
    @media (max-width:550px) and (orientation:landscape) {
    /* Style adjustments for landscape mode goes here */
        top: 65%;
    }
    .whatsapp{
        background-color: #00D95D;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            @media (max-width:1024px) {
                width: 25px;
            }
        }
    }
    .contacto{
        background-color: #00D95D;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        a{
            color: white;
            text-decoration: none;
            font-size: 18px;
            @media (max-width:1024px) {
               font-size: 15px;
            }
        }
    }
`
const Hero = styled.div`
    width: 100%;
    height: 100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width:1000px) and (orientation:landscape) {
        min-height: 900px;
    }
    @media (min-width: 2000px) {
        min-height: 900px;
    }
    #scroll-down{
        width: 140px;
        height: 140px;
        position: absolute;
        bottom: 0;
        @media (max-width:1000px) and (orientation:landscape) {
            bottom: -500px;
        }
        @media (max-width: 550px) {
            width: 100px;
            height: 100px;
        }
    }
    .fondo{
        position: absolute;
        width: 100%;
        height: 100vh;
        background: #011F31 0% 0% no-repeat padding-box;
        mix-blend-mode: hard-light;
        opacity: 0.78;
    }
    .over{
        position: absolute;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 725px;
        z-index: 9;
        @media (min-width:1600px) {
            width:55%;
        }
        @media (max-width:550px) {
            width: 100%;
            padding: 0 20px;
        }
        @media (max-width:1000px) and (orientation:landscape) {
            width: 695px;
            left: 25px;
        }
        p{
            color: white;
            font-size: 18px;
            width: 63%;
            margin-bottom: 20px;
            @media (min-width:1600px) {
                font-size: 20px;
            }
            @media (max-width:550px) {
                width: 80%;
                font-size: 15px;
            }
        }
        h1{
            color: WHITE;
            font-family: "renderinc";
            font-size: 95px;
            line-height: 85px;
            width: 100%;
            opacity: 1;
            margin-bottom: 20px;
            @media (min-width:1600px) {
                font-size: 120px;
                line-height: 100px;
                margin-bottom: 30px;
            }
            @media (max-width:860px) {
                font-size: 70px;
                line-height: 60px;
            }
            @media (max-width:550px) {
                font-size: 40px;
                line-height: 40px;
            }
            &.process_title{
                width: 50%;
                font-family: "SFProDisplay-Black";
            }
        }
        h2{
            font-family: "SFProDisplay-Black";
            color: #ff3155;
            font-size: 35px;
            margin-bottom: 20px;
            @media (min-width:1600px) {
                font-size: 45px;
                margin-bottom: 30px;
            }
            @media (max-width:550px) {
                font-size: 25px;
                line-height: 20px;
            }
        }
        .button{
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            a{
                background-color: #FF5584;
                padding: 10px 20px;
                color: white;
            }
        }
    }
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`
const Video = styled.div`
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (min-width: 2000px) {
        min-height: 900px;
    }
    @media (max-width: 1024px) {
        height: 50vh;
    }
    @media (max-width:1000px) and (orientation:landscape) {
    /* Style adjustments for landscape mode goes here */
    min-height: 500px;
    }   
    a{
        position: absolute;
        cursor: pointer;
        //bottom: 27%;
        img{
            width: 100px;
            @media (max-width: 550px) {
                width: 60px;
            }
        }
    }
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .button_video{
        position: absolute;
        bottom: 10%;
    }
`
const About = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    min-height: 740px;
    @media (min-width: 1600px) {
        min-height: 1000px;
    }
    @media (max-width:1024px) {
        height: 90vh;
        min-height: 1000px;
    }
    @media (max-width:550px) and (orientation:landscape) {
    /* Style adjustments for landscape mode goes here */
    min-height: 800px;
    }
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (max-width:550px) {
        height: 100vh;
        min-height: 900px;
    }
    .about{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        position: absolute;
        @media (max-width:1024px) {
            width: 95%;
            flex-direction: column;
        }
        .titulo_about{
            position: absolute;
            width: 45%;
            height: 30%;
            left: 0;
            padding-left: 100px;
            transition: left 2s ease;
            @media (min-width:1600px) {
                padding-left: 150px;
            }
            @media (max-width:1024px) {
                width: 100%;
                top: 50px;
                padding-left: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
            @media (max-width: 550px) {
               padding: 0px 20px;
               top: 95px;
               
            }
            h1{
                color: #00E15F;
                font-family: "SFProDisplay-Black";
                font-size: 80px;
                margin-bottom: 30px;
                @media (min-width:1600px) {
                    font-size: 100px;
                }
                @media (max-width:860px) {
                    font-size: 70px;
                }
                @media (max-width:550px) {
                    font-size: 40px;
                    width: 100%;
                    line-height: 40px;
                    text-align: center;
                }
            }
            p{
                color: white;
                font-size: 18px;
                width: 75%;
                @media (min-width:1600px) {
                    font-size: 20px;
                    width: 65%;
                }
                @media (max-width: 550px) {
                    width: 100%;
                    text-align: center;
                }
            }
        }
        .hide_left{
            left: -80%;
            transition: left 2s ease;
            @media (max-width: 550px) {
                left: -100%;
            }
        }
        .hide_right{
            position: absolute !important;
            height: 0 !important;
            right: -1000px;
            /* transform: translateX(1000px); */
            //display: none !important;
            /* height: 0px !important; */
            /* transition: height 3s ease-in-out; */
            /* transition: transform 2s ease; */
        }
        .servicios{
            position: absolute;
            width: 60%;
            height: 45%;
            right: 0;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
           
            @media (min-width: 1600px) {
                width: 55%;
                padding-right: 30px;
            }
            @media (max-width:1024px) {
                width: 100%;
                bottom: 100px;
                padding-right: 0;
                justify-content: space-between;
            }
            @media (max-width:550px) {
               flex-wrap: wrap;
               height: 60%;
               padding-top: 50px;
               justify-content: space-evenly;
            }
            .iconos{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 28%;
                height: 180px;
                justify-content: flex-start;
                cursor: pointer;
                overflow: hidden;
                position: relative;
                transition: transform 1s ease, width 0.5s ease, height 0.5s ease;
                @media (min-width: 1600px) {
                    height: 210px;
                }
                @media (max-width:550px) {
                    width: 50%;
                    height: 145px;
                }

                &:nth-child(4){
                    .text-serv{
                        p{
                            width: 70%;
                        }
                    }
                }
                .text-serv{
                    position: absolute;
                    bottom: 0;
                    width: auto;
                    overflow: hidden;
                    height: 60%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: width 1s ease, position 1s ease, height 1s ease, overflow 1s ease;
                }
                img{
                    width: 70px;
                    height: 50%;
                    transition: opacity 1s ease, width 2s ease, height 1s ease, transform 1s ease;
                    @media (min-width: 1600px) {
                        height: 40%;
                    }
                    @media (max-width:550px) {
                        width: 50px;
                    }
                    @media (min-width: 1200px) {
                        &:hover{
                        transform: scale(1.2);
                        transition: transform 1s ease;
                    }
                    }
                }
                
                h3{
                    text-align: center;
                    width: 100%;
                    color: white;
                    font-family: "SFProDisplay-semibold";
                    font-size: 20px;
                    transform: translate(0);
                    transition: transform 0.5s ease, font-size 1s ease;
                    @media (min-width:1600px) {
                        font-size: 30px;
                    }
                    @media (max-width:550px) {
                        font-size: 20px;
                    }
                }
                p{
                    color: white;
                    text-align: center;
                    position: absolute;
                    transform: translateY(-200px);
                    transition: transform 0.5s ease;
                    @media (min-width:1600px) {
                        font-size: 22px;
                    }
                    @media (max-width:550px) {
                        font-size: 13px;
                    }
                }
            }
        }
        .enlarge_left{
            width: 100%!important;
            height: 550px !important;
            justify-content: space-around !important;
            transform: translate(-678px,-140px);
            transition: transform 1s ease;
            @media (min-width: 1600px) {
                transform: translate(-950px,-120px);
            }
            @media (max-width:1024px) {
                width: 80% !important;
                transform: translate(-30px,50px);
            }
            @media (max-width: 550px) {
                width: 100% !important;
                transform: translate(-75px,-50px);
            }
            img{
                /* position: absolute; */
                width: 500px !important;
                height: 600px !important;
                opacity: 0.3;
                transform: translateX(-200px);
                transition: opacity 1s ease, width 1s ease, height 1s ease;
                @media (max-width:1024px) {
                    width: 300px !important;
                }
                @media (max-width: 550px) {
                    width: 200px !important;
                    transform: translateX(-70px);
                }
            }
            .text-serv{
                width: 70% !important;
                height: auto !important;
                position: absolute !important;
                z-index: 9;
                bottom: auto !important;
                line-height: 73px;
                transition: position 1s ease, height 1s ease;
                @media (min-width: 1600px) {
                    width: 68% !important;
                    line-height: 80px;
                }
                @media (max-width:1024px) {
                   left: 50px;
                }
                @media (max-width: 550px) {
                    left: auto;
                    left: 85px;
                    width: 77% !important;
                }
                h3{
                    font-family: "SFProDisplay-Black";
                    font-size: 80px !important;
                    text-align: start;
                    transition: font-size 2s ease;
                    @media (min-width: 1600px) {
                        font-size: 90px !important;
                        line-height: 75px;
                    }
                    @media (max-width:1024px) {
                        font-size: 50px !important;
                        line-height: 50px;
                    }
                    @media (max-width: 550px) {
                        font-size: 35px !important;
                        line-height: 35px;
                    }
                }
            }
        }
        .showConcepto{
            width: 25%;
            height: 190px;
            position: absolute;
            left: 4%;
            bottom: 15%;
            @media (min-width: 1600px) {
                bottom: 25%;
            }
            @media (max-width:1024px) {
                width: 45%;
                left: auto;
                right: 0%;
                bottom: 10%;
            }
            @media (max-width: 550px) {
                width: 85%;
                left: 4%;
                right: auto;
                bottom: 10%;
            }
            p{
                text-align: left !important;
                color: white;
                font-size: 20px;
                margin-bottom: 20px;
            }
            &.metaverso{
                height: 225px;
            }
            
        }
        .showDetails{
            width: 45%;
            height: 70%;
            position: absolute;
            top: 10%;
            right: 7%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            @media (min-width: 1600px) {
                top: 15%;
                right: 10%;
                height: 70%;
                width: 41%;
            }
            @media (max-width:1024px) {
                width: 95%;
                top: 5%;
                right: auto;
            }
            @media (max-width: 550px) {
                 height: 45%;
                 top: 8%;
                 justify-content: space-evenly;
            }
            .close{
                color: white;
                font-size: 30px;
                cursor: pointer;
                padding-bottom: 50px;
                position: absolute;
                top: 0;
                @media (min-width: 1600px) {
                    padding-bottom: 100px;
                }
            }
            .text-details{
                width: 100%;
                color: #00E15F;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: auto;
                h2{
                    /* font-size: 90px; */
                    font-size: 6vw;
                    line-height: 75PX;
                    font-family: "SFProDisplay-Black";
                    @media (min-width: 1600px) {
                        line-height: 90px;
                       
                    }
                    @media (max-width:1024px) {
                        font-size: 11.5vw;
                    }
                    @media (max-width: 550px) {
                        font-size: 53px;
                        line-height: 53px;
                    }
                }
                h1{
                    /* font-size: 85px; */
                    font-size: 5.6vw;
                    line-height: 75PX;
                    font-family: "SFProDisplay-Black";
                    @media (min-width: 1600px) {
                        line-height: 105px;
                    }
                    @media (max-width:1024px) {
                        font-size: 10.6vw;
                    }
                    @media (max-width: 550px) {
                        font-size: 50px;
                        line-height: 50px;
                    }
                }
                h3{
                    //font-size: 56px;
                    font-size: 3.5vw;
                    line-height: 56PX;
                    font-family: "SFProDisplay-Black";
                    @media (min-width: 1600px) {
                        line-height: 90px;
                    }
                    @media (max-width:1024px) {
                        font-size: 7vw;
                    }
                    @media (max-width: 550px) {
                        font-size: 33px;
                        line-height: 33px;
                    }
                }
                h4{
                    //font-size: 47px;
                    font-size: 3vw;
                    line-height: 56PX;
                    font-family: "SFProDisplay-Black";
                    @media (min-width: 1600px) {
                        line-height: 90px;
                    }
                    @media (max-width:1024px) {
                        font-size: 5.8vw;
                    }
                    @media (max-width: 550px) {
                        font-size: 27px;
                        line-height: 27px;
                    }
                }
                h5{
                    font-size: 83px;
                    line-height: 83PX;
                    font-family: "SFProDisplay-Black";
                    @media (min-width: 1600px) {
                        font-size: 95px;
                       line-height: 90px;
                    }
                    @media (max-width: 550px) {
                        font-size: 28px;
                        line-height: 28px;
                    }
                }
                h6{
                    font-size: 40px;
                    line-height: 45PX;
                    font-family: "SFProDisplay-Black";
                    @media (min-width: 1600px) {
                        font-size: 55px;
                       line-height: 50px;
                    }
                    @media (max-width: 550px) {
                        font-size: 28px;
                        line-height: 28px;
                    }
                }
                &.film{
                    h2{
                        font-size: 83px;
                        line-height: 83PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 83px;
                            line-height: 80px;
                        }
                        @media (max-width: 550px) {
                            font-size: 11vw;
                            line-height: 35px;
                        }
                    }
                    h3{
                        font-size: 69px;
                        line-height: 70PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 70px;
                            line-height: 70px;
                        }
                        @media (max-width: 550px) {
                            font-size: 9vw;
                            line-height: 35px;
                        }
                    }
                    h4{
                        font-size: 60px;
                        line-height: 60PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 60px;
                            line-height: 60px;
                        }
                        @media (max-width: 550px) {
                            font-size: 8vw;
                            line-height: 35px;
                        }
                    }
                    h5{
                        font-size: 56px;
                        line-height: 56PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 56px;
                            line-height: 56px;
                        }
                        @media (max-width: 550px) {
                            font-size: 7.5vw;
                            line-height: 35px;
                        }
                    }
                    h6{
                        font-size: 49px;
                        line-height: 50px;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 50px;
                        line-height: 50px;
                        }
                        @media (max-width: 550px) {
                            font-size: 6.5vw;
                            line-height: 35px;
                        }
                    }
                }
                &.virtual{
                    h2{
                        font-size: 3.8vw;
                        line-height: 60px;
                        @media (min-width: 1600px) {
                            line-height: 80px;
                        }    
                        @media (max-width:1024px) {
                            font-size: 8.7vw;
                        }
                        @media (max-width: 550px) {
                            font-size: 8.3vw;
                            line-height: 40px;
                        }
                    }
                    h3{
                        font-size: 4.3vw;
                        line-height: 60PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            line-height: 86px;
                        }
                        @media (max-width:1024px) {
                            font-size: 9.8vw;
                        }
                        @media (max-width: 550px) {
                            font-size: 9.5vw;
                            line-height: 40px;
                        }
                    }
                    h4{
                        font-size: 3.5vw;
                        line-height: 60PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            line-height: 60px;
                        }
                        @media (max-width:1024px) {
                            font-size: 8vw;
                        }
                        @media (max-width: 550px) {
                            font-size:7.8vw;
                            line-height: 40px;
                        }
                    }
                    h5{
                        @media (min-width: 1600px) {
                           font-size: 5.5vw;
                        }
                        @media (max-width:1024px) {
                            font-size: 12vw;
                        }
                        @media (max-width: 550px) {
                            font-size: 12vw;
                            line-height: 40px;
                        }
                    }
                }
                &.branding{
                    h2{
                        line-height: 100px;
                        @media (max-width:1024px) {
                            font-size: 16vw;
                            line-height: 90px;
                        }
                        @media (max-width: 550px) {
                                line-height: 50px;
                        }
                    }
                    h3{
                        font-size: 4.2vw;
                        line-height: 63PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 4.3vw;
                            line-height: 90px;
                        }
                        @media (max-width:1024px) {
                            font-size: 11.2vw;
                            line-height: 90px;
                        }
                        @media (max-width: 550px) {
                            font-size: 11.2vw;
                            line-height: 40px;
                        }
                    }
                    h4{
                        line-height: 35px;
                        @media (min-width: 1600px) {
                            line-height: 80px;
                        }
                        @media (max-width:1024px) {
                            font-size: 8.4vw;
                            line-height: 70px;
                        }
                        @media (max-width: 550px) {
                                line-height: 40px;
                        }
                    }
                    h5{
                        font-size: 3.9vw;
                        line-height: 70PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 4vw;
                            line-height: 90px;
                        }
                        @media (max-width:1024px) {
                            font-size: 10.5vw;
                            line-height: 70px;
                        }
                        @media (max-width: 550px) {
                            font-size: 10.5vw;
                            line-height: 40px;
                        }
                    }
                }
                &.web{
                    h1{
                        font-size: 3.1vw;
                        line-height: 65px;
                        @media (min-width: 1600px) {
                            font-size: 3vw;
                            line-height: 70px;
                        }
                        @media (max-width:1024px) {
                            font-size: 7.4vw;
                            line-height: 60px;
                        }
                        @media (max-width: 550px) {
                            font-size: 7vw;
                            line-height: 35px;
                        }
                    }
                    h2{
                        font-size: 3.5vw;
                        line-height: 50PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                           font-size: 3.4vw;
                            line-height: 70px;
                        }
                        @media (max-width:1024px) {
                            font-size: 8.4vw;
                            line-height: 60px;
                        }
                        @media (max-width: 550px) {
                            font-size: 8.2vw;
                            line-height: 35px;
                        }
                    }
                    h3{
                        font-size: 4.8vw;
                        line-height: 65px;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 4.9vw;
                            line-height: 70px;
                        }
                        @media (max-width:1024px) {
                            font-size: 11.5vw;
                            line-height: 80px;
                        }
                        @media (max-width: 550px) {
                            font-size: 11.5vw;
                            line-height: 46px;
                        }
                    }
                    h4{
                        font-size: 3.1vw;
                        line-height: 65PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 3vw;
                            line-height: 70px;
                        }
                        @media (max-width:1024px) {
                            font-size: 7.4vw;
                            line-height: 60px;
                        }
                        @media (max-width: 550px) {
                            font-size: 7vw;
                            line-height: 35px;
                        }
                    }
                    h5{
                        font-size: 3vw;
                        line-height: 65PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 2.8vw;
                            line-height: 70px;
                        }
                        @media (max-width:1024px) {
                            font-size: 7vw;
                            line-height: 60px;
                        }
                        @media (max-width: 550px) {
                            font-size: 7vw;
                            line-height: 35px;
                        }
                    }
                    h6{
                        font-size: 2.6vw;
                        line-height: 65PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 2.5vw;
                            line-height: 70px;
                        }
                        @media (max-width:1024px) {
                            font-size: 6vw;
                            line-height: 70px;
                        }
                        @media (max-width: 550px) {
                            font-size: 6vw;
                            line-height: 35px;
                        }
                    }
                }
                &.meta{
                    h1{
                        line-height: 70px;
                        font-size: 5.2vw;
                        @media (min-width: 1600px) {
                            line-height: 96px;
                            font-size: 5.2vw;
                        }
                        @media (max-width:1024px) {
                            font-size: 12.8vw;
                            line-height: 90px;
                        }
                        @media (max-width: 550px) {
                            font-size: 13vw;
                            line-height: 63px;
                        }
                    }
                    h2{
                        font-size: 3vw;
                        line-height: 60px;
                        @media (min-width: 1600px) {
                            line-height: 86px;
                        }
                        @media (max-width:1024px) {
                            font-size: 7.5vw;
                            line-height: 70px;
                        }
                        @media (max-width: 550px) {
                            font-size:7.6vw;
                            line-height: 35px;
                        }
                    }
                    h3{
                        font-size: 4vw;
                        line-height: 60PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            line-height: 86px;
                        }
                        @media (max-width:1024px) {
                            font-size: 10vw;
                            line-height: 90px;
                        }
                        @media (max-width: 550px) {
                            font-size: 10vw;
                            line-height: 43px;
                        }
                    }
                    h4{
                        font-size: 3.5vw;
                        line-height: 60px;
                        @media (min-width: 1600px) {
                            line-height: 86px;
                        }
                        @media (max-width:1024px) {
                            font-size: 8.5vw;
                            line-height: 90px;
                        }
                        @media (max-width: 550px) {
                            font-size: 8.4vw;
                            line-height: 28px;
                        }
                    }
                    h6{
                        font-size: 4.5vw;
                        line-height: 60px;
                        @media (min-width: 1600px) {
                            line-height: 86px;
                        }
                        @media (max-width:1024px) {
                            font-size: 11.5vw;
                            line-height: 90px;
                        }
                        @media (max-width: 550px) {
                            font-size: 11.6vw;
                            line-height: 45px;
                        }
                    }
                }
            }
        }
        &.eng{
            font-size: large;
            .showDetails{
                .text-details{
                    h4{
                        font-size: 2.5vw;
                        @media (max-width:1024px) {
                            font-size: 5.8vw;
                        }
                        @media (max-width: 550px) {
                            font-size: 7.3vw;
                        }
                    }
                    h1{
                        font-size: 5vw;
                        @media (max-width:1024px) {
                            font-size: 11.5vw;
                        }
                        @media (max-width: 550px) {
                            font-size: 14vw;
                        }
                    }
                    h2{
                        @media (max-width:1024px) {
                            font-size: 13.5vw;
                        }
                        @media (max-width: 550px) {
                            font-size: 17vw;
                        }
                    }
                    h3{
                        @media (max-width:1024px) {
                            font-size: 8vw;
                        }
                        @media (max-width: 550px) {
                            font-size: 10vw;
                        }
                    }
                    &.film{
                        h4{
                            font-size: 4vw;
                            @media (min-width: 1600px) {
                                font-size: 3vw;
                            }
                            @media (max-width:1024px) {
                                font-size: 7.9vw;
                            }
                            @media (max-width: 550px) {
                                font-size: 8.5vw;
                                line-height: 40px;
                            }
                        }
                        h5{
                            @media (max-width: 550px) {
                                font-size: 7.8vw;
                                line-height: 40px;
                            }
                        }
                        h6{
                            font-size: 5vw;
                            line-height: 60px;
                            @media (min-width: 1600px) {
                                font-size: 3.7vw;
                                line-height: 90px;
                            }
                            @media (max-width:1024px) {
                                font-size: 10vw;
                            }
                            @media (max-width: 550px) {
                                font-size: 10.5vw;
                                line-height: 40px;
                            }
                        }
                        h2{
                            @media (max-width:1024px) {
                                font-size: 10.8vw;
                            }
                            @media (max-width: 550px) {
                                font-size: 11.5vw;
                                line-height: 40px;
                            }
                        }
                        h3{
                            font-size: 5vw;
                            @media (min-width: 1600px) {
                                font-size: 3.6vw;
                                line-height: 90px;
                            }
                            @media (max-width:1024px) {
                                font-size: 9.8vw;
                            }
                            @media (max-width: 550px) {
                                font-size: 10vw;
                                line-height: 40px;
                            }
                        }
                    }
                    &.virtual{
                        h4{
                            font-size: 5.2vw;
                            @media (min-width: 1600px) {
                                font-size: 4.3vw;
                            }
                            @media (max-width:1024px) {
                                font-size: 11vw;
                                line-height: 70px;
                            }
                            @media (max-width: 550px) {
                                font-size: 11vw;
                                line-height: 45px;
                            }
                        }
                        h3{
                            font-size: 4.6vw;
                            @media (min-width: 1600px) {
                                font-size: 3.8vw;
                            }
                            @media (max-width:1024px) {
                                font-size: 9.7vw;
                                line-height: 70px;
                            }
                            @media (max-width: 550px) {
                                font-size: 9.4vw;
                                line-height: 45px;
                            }
                        }
                        h2{
                            font-size: 6vw;
                            @media (min-width: 1600px) {
                                font-size: 4.8vw;
                            }
                            @media (max-width:1024px) {
                                font-size: 12.4vw;
                                line-height: 70px;
                            }
                            @media (max-width: 550px) {
                                font-size: 12.2vw;
                                line-height: 45px;
                            }
                        }
                        h5{
                            font-size: 5.7vw;
                            @media (min-width: 1600px) {
                                font-size: 4.8vw;
                            }
                            @media (max-width: 550px) {
                                line-height: 50px;
                                font-size: 12vw;
                            }
                        }
                    }
                    &.branding{
                        h2{
                            @media (max-width:1024px) {
                                font-size: 16.5vw;
                                line-height: 90px;
                            }
                            @media (max-width: 550px) {
                                line-height: 50px;
                            }
                        }
                    h4{
                        font-size: 3.3vw;
                        line-height: 40px;
                        @media (min-width: 1600px) {
                            line-height: 80px;
                        }
                        @media (max-width:1024px) {
                            font-size: 9vw;
                            line-height: 90px;
                        }
                        @media (max-width: 550px) {
                            font-size: 9.3vw;
                            line-height: 40px;
                        }
                    }
                    h3{
                        font-size: 4.6vw;
                        line-height: 63PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 4.8vw;
                            line-height: 90px;
                        }
                        @media (max-width:1024px) {
                            font-size: 12.8vw;
                            line-height: 90px;
                        }
                        @media (max-width: 550px) {
                            font-size: 13vw;
                            line-height: 50px;
                        }
                    }
                    h5{
                        font-size: 5.5vw;
                        line-height: 70PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 5.5vw;
                            line-height: 90px;
                        }
                        @media (max-width:1024px) {
                            font-size: 15.4vw;
                            line-height: 90px;
                        }
                        @media (max-width: 550px) {
                            font-size: 15.5vw;
                            line-height: 50px;
                        }
                    }
                    }
                    &.web{
                        h1{
                            font-size: 3.8vw;
                            @media (min-width: 1600px) {
                                font-size: 3.7vw;
                            }
                            @media (max-width:1024px) {
                                font-size: 9vw;
                                line-height: 70px;
                            }
                            @media (max-width: 550px) {
                                font-size: 8.8vw;
                                line-height: 35px;
                            }
                        }
                    h2{
                        font-size: 3.3vw;
                        line-height: 50PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 70px;
                            line-height: 70px;
                        }
                        @media (max-width:1024px) {
                            font-size: 8vw;
                            line-height: 70px;
                        }
                        @media (max-width: 550px) {
                            font-size: 8vw;
                            line-height: 35px;
                        }
                    }
                    h3{
                        font-size: 3.8vw;
                        line-height: 60px;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 3.8vw;
                            line-height: 70px;
                        }
                        @media (max-width:1024px) {
                            font-size: 9vw;
                            line-height: 70px;
                        }
                        @media (max-width: 550px) {
                            font-size: 9vw;
                            line-height: 46px;
                        }
                    }
                    h4{
                        font-size: 2.5vw;
                        line-height: 60PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 2.5vw;
                            line-height: 70px;
                        }
                        @media (max-width:1024px) {
                            font-size: 6vw;
                            line-height: 60px;
                        }
                        @media (max-width: 550px) {
                            font-size: 6vw;
                            line-height: 35px;
                        }
                    }
                    h5{
                        font-size: 3vw;
                        line-height: 60PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 2.8vw;
                            line-height: 70px;
                        }
                        @media (max-width:1024px) {
                            font-size: 7vw;
                            line-height: 70px;
                        }
                        @media (max-width: 550px) {
                            font-size: 7vw;
                            line-height: 35px;
                        }
                    }
                    h6{
                        font-size: 2.7vw;
                        line-height: 60PX;
                        font-family: "SFProDisplay-Black";
                        @media (min-width: 1600px) {
                            font-size: 2.7vw;
                            line-height: 70px;
                        }
                        @media (max-width:1024px) {
                            font-size: 6.4vw;
                            line-height: 60px;
                        }
                        @media (max-width: 550px) {
                            font-size: 6.5vw;
                            line-height: 35px;
                        }
                    }
                    }
                    &.meta{
                        h1{
                            @media (max-width:1024px) {
                                font-size: 13.4vw;
                                line-height: 90px;
                            }
                            @media (max-width: 550px) {
                                font-size: 13.5vw;
                                line-height: 45px;
                            }
                        }
                        h2{
                            @media (max-width:1024px) {
                                font-size: 8vw;
                                line-height: 70px;
                            }
                            @media (max-width: 550px) {
                                font-size: 8vw;
                                line-height: 35px;
                            }
                        }
                        h4{
                            font-size: 3vw;
                            @media (max-width:1024px) {
                                font-size: 8vw;
                                line-height: 70px;
                            }
                            @media (max-width: 550px) {
                                font-size: 8.4vw;
                                line-height: 35px;
                            }
                        }
                        h6{
                            line-height: 70px;
                            @media (max-width: 550px) {
                                font-size: 11.6vw;
                                line-height: 35px;
                            }
                            
                        }
                    }
                }
            }
        }
    }

`
const Proyectos = styled.div`
    width: 100%;
    height: 100vh;
    @media (max-width:1024px) {
         height: 70vh;
         min-height: 900px;
    }
    @media (min-width: 2000px) {
        min-height: 900px;
    }
    @media (max-width: 550px) {
        min-height: 600px;
    }
    .swiper{
        width: 100%;
        height: 100%;
        background-color: black;
    }
    span.swiper-pagination-bullet.swiper-pagination-bullet-active:after{
        width: 16px;
        height: 16px;
        content: "";
        display: block;
        background: transparent;
        border-radius: 50%;
        border: solid 2px white;
        position: absolute;
        left: -5px;
        top: -5px;
    }
    .swiper-pagination-bullet{
        width: 10px !important;
        height: 10px !important;
        background-color: white;
        position: relative;
        margin: 0 8px !important;
    }
    .swiper-pagination-bullet-active{
        background-color: white !important;
    }
    .swiper-pagination{
        bottom: 20% !important;
        width: 20% !important;
        @media (min-width:1600px) {
            bottom: 18% !important;
            width: 20% !important;
        }
        @media (max-width:1024px) {
            width: 15% !important;
        }
        @media (max-width:550px) {
            width: 27% !important;
            bottom: 10% !important;
        }
    }
    .carousel__slider{
        width: 100%;
        height: 100%;
        background-color: black;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        .slider_text{
            width: 45%;
            height: 55%;
            position: absolute;
            margin-left: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            @media (min-width:1600px) {
                height: 55%;
                width: 40%;
                margin-left: 150px;
            }
            @media (max-width:1024px) {
                width: 55%;
                height: 50%;
                margin-left: 20px;
            }
            @media (max-width:550px) {
                margin-left: 0px;
                padding: 0 20px;
                width: 100%;
                height: 60%;
            }
            h1{
                color: white;
                font-family: "SFProDisplay-Black";
                font-size: 80px;
                line-height: 75px;
                @media (min-width:1600px) {
                    font-size: 120px;
                    line-height: 110px;
                }
                @media (max-width:1024px) {
                    font-size: 70px;
                    line-height: 70px;
                }
                @media (max-width:550px) {
                    font-size: 40px;
                    width: 100%;
                    line-height: 40px;
                }
            }
            h3{
                color: #00E15F;
                &.green{
                    color: #00E15F;
                }
                @media (max-width:550px) {
                    font-size: 15px;
                }
            }
            p{
                color: white;
                font-size: 18px;
                @media (min-width:1600px) {
                    font-size: 20px;
                    width: 75%;
                }
            }
        }
    }
    @media (max-width:550px) and (orientation:landscape) {
    /* Style adjustments for landscape mode goes here */
        min-height: 800px;
        .slider_text{
            width: 70% !important;
            height: 55% !important;
        }
    }
`
const Contacto = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #011F31;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @media (max-width:1024px) {
        flex-direction: column;
        height: 90vh;
    }
    @media (max-width:550px) {
     height: 80vh;
     min-height: 700px;
     padding: 0px 20px;
    }
    @media (max-width:1000px) and (orientation:landscape) {
    /* Style adjustments for landscape mode goes here */
    min-height: 800px;
    }
    h1{
        color: #00E15F;
        font-family: "SFProDisplay-Black";
        font-size: 80px;
        @media (min-width:1600px) {
            font-size: 100px;
        }
        @media (max-width:860px) {
            font-size: 70px;
        }
        @media (max-width:550px) {
            font-size: 40px;
            padding-top: 50px;
        }
    }
    .formulario{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        @media (max-width:1024px) {
            height: 50%;
            width: 80%;
            justify-content: center;
        }
        @media (max-width:550px) {
            height: 80%;
            width: 100%;
        }
        form{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 80%;
            height: 80%;
            @media (max-width:1024px) {
                height: 100%;
            }
            @media (max-width:550px) {
                width: 100%;
                height: 90%;
            }
            .recaptcha{
                width: 100%;
            }
            input{
                background-color: transparent;
                border: none;
                border-bottom: 2px solid white;
                color: #00E15F;
                height: 50px;
                outline: none;
                font-family: "SFProDisplay-Black";
                font-size: 25px;
                @media (min-width:1600px) {
                    font-size: 30px;
                }
                @media (max-width:550px) {
                    font-size: 20px;
                }
                &::placeholder{
                    color: #00E15F;
                    font-family: "SFProDisplay-Black";
                    font-size: 25px;
                    @media (min-width:1600px) {
                        font-size: 30px;
                    }
                    @media (max-width:550px) {
                        font-size: 20px;
                    }
                }
            }
            div{
                    color: red;
                }
        }
    }
`
const Simulador = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #301877;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    @media (max-width:550px) {
       min-height: 1000px;
    }
    @media (max-width:1000px) and (orientation:landscape) {
    /* Style adjustments for landscape mode goes here */
    min-height: 1100px;
    }
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .left{
        position: absolute;
        width: 35%;
        height: 65%;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 100px;
        @media (min-width:1600px) {
            width: 30%;
            height: 65%;
            margin-left: 150px;
        }
        @media (max-width:1024px) {
           width: auto;
           height: 50%;
           top: 0;
           margin-left: 20px;
        }
        @media (max-width:550px) {
            margin-left: 0;
            padding: 0 20px;
        }
        h1{
            color: white;
            font-family: "SFProDisplay-Black";
            font-size: 80px;
            line-height: 75px;
            @media (max-width:1024px) {
                font-size: 70px;
                line-height: 70px;
            }
            @media (max-width:550px) {
                font-size: 40px;
                line-height: 40px;
            }
        }
        p{
            color: white;
            font-size: 20px;
        }
        .demos_simu{
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            div{
                width: 100%;
                display: flex;
                justify-content: space-between;
                @media (max-width:1024px) {
                    width: 80%;
                }
                @media (max-width:550px) {
                    width: 100%;
                }
                img{
                    @media (max-width:550px) {
                        width: 90px;
                    }
                }
            }
        }
    }
    .linea-vertical{
        width: 2px;
        height: 50%;
        background-color: white;
        position: absolute;
        @media (max-width:1024px) {
            transform: rotate(90deg);   
        }
        @media (max-width:550px) {
            height: auto;
        }
    }
    .right{
        position: absolute;
        width: 50%;
        height: 50%;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width:1024px) {
           width: 100%;
           height: 40%;
           bottom: 0;
           align-items: flex-start;
            padding-left: 20px;
        }   
        @media (max-width:550px) {
            height: 50%;
        }
        ul{
            width: 80%;
            @media (max-width:550px) {
                width: 90%;
            }
            li{
                width: 100%;
                padding: 0px 0 30px 0;
                list-style: none;
                cursor: pointer;
                p{
                    overflow-y: hidden;
                    width: 65%;
                    color: white;
                    height: 0px;
                    opacity: 0;
                    font-size: 0;
                    transition:height 1s ease, opacity 1s ease;
                    @media (max-width:550px) {
                        width: 95%;
                    }
                }
                &:hover p{ 
                    opacity: 1;
                    height: 100px;
                    font-size: 18px;
                    transition:height 2s ease, opacity 1s ease ;
                    padding: 0px 0px 5px 60px;
                    @media (max-width:550px) {
                       font-size: 15px;
                       height: 90px;
                    }
                }
                &:hover .detalles_row .imagen_flecha{
                    background-image: url(../assets/Svg/flecha-hacia-arriba.png);
                }
                .detalles_row{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    h4{
                        color: white;
                        font-size: 24px;
                        margin-right: 10px;
                        &:nth-child(3){
                            color: #00FFFF;
                        }
                        @media (max-width:550px) {
                            font-size: 18px;
                        }
                    }
                    .imagen_flecha{
                        background-image: url(../assets/Svg/flecha-hacia-abajo.png);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        height: 15px;
                        width: 15px;
                        margin-right: 10px;
                    }
                }
            }
        }
        /* .detalles{
            width: 80%;
            padding: 0px 0 30px 0;
            
            .visibility_p{
                width: 65%;
                padding: 0px 0px 5px 60px;
                color: white;
                height: 0px;
               display: none;
                margin: 5px 0;
                transition:height 1s ease;
                font-size: 18px;
            }
        } */
    }
    /* .detalles_row:hover ~ .visibility_p {
                height: auto;
               display: block;
                transition: height 1s ease, opacity 0.2s ease;
    } */
`
const Button = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: ${props => props.primary ? "flex-start" : "center"};
    &.recaptcha{
        @media (max-width:550px) {
            justify-content: center;
        }
    }
    a{
        background-color: ${props => props.color ? "#FF5584" : "#ff3155"};
        padding: 10px 20px;
        color: white;
        transition: background-color 1s ease;
        @media (min-width:1600px) {
            font-size: 22px;
        }
        &:hover{
            background-color: #00D95D;
            transition: background-color 1s ease;
        }
    }
    button{
        background-color: ${props => props.color ? "#FF5584" : "#ff3155"}; ;
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        @media (min-width:1600px) {
            font-size: 22px;
        }
        transition: background-color 1s ease;
        &:hover{
            background-color: #00D95D;
            transition: background-color 1s ease;
        }
    }
`
const Headers = styled.header`
    width: 100%;
    height: 70px;
    position: fixed;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    &.back_menu{
        background-color: black;
    }
    @media (max-width:550px) {
        height: 75px;
    }
    .logo{
        width: 15%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        margin-left: 100px;
        @media (min-width:1600px) {
            margin-left: 150px;
        }
        @media (max-width: 1024px) {
            margin-left: 25px;
        }
        @media (max-width:550px) {
            width: 40%;
            margin-left: 15px;
        }
        a{
            align-items: center;
            display: flex;
            justify-content: center;
        }
        img{
            width: 212px;
            @media (max-width:550px) {
                    width: 85%;
            }
        }
    }
    .icon_menu{
        width: 20%;
        height: 75px;
        display: flex;
        color: white;
        align-items: center;
        justify-content: flex-end;
        @media (max-width:1024px) {
            padding-left: 0;
        }
        @media (max-width:550px) {
            width: 27%;
        }
    }
    .btn-toggle {
        width: 50px;
        height: 50px;
        margin-right: 0px !important;
        cursor: pointer;
        position: relative;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            height: 3.8px;
            width: 60%;
            background-color: white;
            transition: width .5s;
            &::before, &::after {
                content: '';
                width: 100%;
                height: 100%;
                background-color:white;
                position: absolute;
                left: 0;
                transition: width .5s;
            }
    
            &::before {
                top: -8px;
            }
            &::after {
                bottom: -7px;
            }
            &::before, &::after {
                transition: all .3s;
            }
        }
    }
    .socials{
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        margin-right: 100px;
        @media (min-width:1600px) {
            margin-right: 150px;
        }
        @media (max-width:1024px) {
            width: 40%;
            margin-right: 20px;
        }
        @media (max-width:550px) {
            width: 60%;
            margin-right: 10px;
        }
        .idioma{
            width: 110px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;
            @media (max-width: 550px) {
                display: none;
            }
            button{
                background-color: transparent;
                color: white;
                font-family: "SFProDisplay-Black";
                font-size: 18px;
                border: none;
                border-bottom: 2px solid transparent;
                &.activo{
                    border-bottom: 2px solid white;
                }
            }

        }
        .portafolio{
            width: 150px;
            height: 100%;
            background-color: #ff3155;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width:550px) {
                width: 90px;
            }
            a{
                color: white;
                font-family: "SFProDisplay-Black";
                font-size: 18px;
                @media (max-width:550px) {
                    font-size: 13px;
                }
            }
        }
    }

`
const Footers = styled.div`
    width: 100%;
    height: 150px;
    background-color: #011F31;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 20px;
    img{
        width: 180px;
    }
    p{
        color: white;
        font-size: 12px;
        @media (max-width:550px) {
            text-align: center;
        }
    }
    div{
        p{
            font-size: 20px;
        }
    }
`
const Concepto = styled.div`
    width: 100%;
    height: 80vh;
    background-color: #011F31;
    display: flex;
    align-items: center;
    @media (max-width:1024px) {
        flex-direction: column;
        justify-content: space-evenly;
    }
    @media (max-width:1000px) and (orientation:landscape) {
        min-height: 900px;
    }
    .left{
        width: 40%;
        display: flex;
        flex-direction: column;
        padding-left: 100px;
        @media (min-width:1600px) {
            padding-left: 150px;
        }
        @media (max-width:1024px) {
            width: 100%;
            padding-left: 50px;
        }
        @media (max-width:550px) {
            padding: 0 20px;
        }
        h2{
            color: white;
            font-size: 80px;
            line-height: 75px;
            padding-bottom: 20px;
            font-family: "SFProDisplay-Black";
            @media (max-width:550px) {
                font-size: 45px;
                line-height: 45px;
            }
        }
        p{
            color: white;
            font-size: 18px;
            width: 80%;
            @media (max-width:550px) {
                font-size: 15px;
            }
        }
    }
    .right{
        width: 75%;
        height: 45%;
        color: #03DBEA;
        padding-left: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        @media (min-width:1600px) {
            padding-left: 150px;
        }
        @media (max-width:1024px) {
            width: 100%;
            padding-left: 50px;
        }
        @media (max-width:550px) {
            padding: 0 20px;
            height: 30%;
        }
        h1{
            font-family: "SFProDisplay-Black";
            font-size: 110px;
            line-height: 100px;
            @media (max-width:550px) {
                font-size: 45px;
                line-height: 45px;
            }
        }
        h2{
            font-family: "SFProDisplay-Black";
            font-size: 100px;
            line-height: 95px;
            @media (max-width:550px) {
                font-size: 45px;
                line-height: 45px;
            }
        }
        h3{
            font-family: "SFProDisplay-Black";
            font-size: 53px;
            line-height: 53px;
            @media (max-width:550px) {
                font-size: 30px;
                line-height: 30px;
            }
        }
        h4{
            font-family: "SFProDisplay-Black";
            font-size: 70px;
            line-height: 70px;
            @media (max-width:550px) {
                font-size: 30px;
                line-height: 30px;
            }
        }
        h5{
            font-family: "SFProDisplay-Black";
            font-size: 60px;
            line-height: 60px;
            @media (max-width:550px) {
                font-size: 45px;
                line-height: 45px;
            }
        }
    }
`
const Proceso = styled.div`
    width: 100%;
    height: 140vh;
    background-color: #6A0042;
    padding: 100px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width:1000px) and (orientation:landscape) {
        min-height: 900px;
    }
    /* @media (min-width: 2000px) {
        min-height: 300vh;
    } */
    @media (max-width:1024px) {
        height: 85vh;
        padding-bottom: 0;
    }
    @media (max-width:550px) {
        height: 100vh;
        min-height: 900px;
        justify-content: space-evenly;
        padding: 0;
    }
    .content_arrow{
        position: absolute;
        left: 48%;
        top: 25%;
        height: 140vh;
        overflow: hidden;
        @media (min-width:1600px) {
            height: 135vh;
            top: 20%;
        }
        @media (max-width:1024px) {
            display: none;
        }
        .arrow{
            div{
                height: 100%;
                img{
                    /* height: 100%; */
                    height: 600px;
                    @media (min-width:1600px) {
                        height: 800px;
                    }
                }
            }
        }
    }
    .proceso{
        width: 100%;
        height: 70%;
        display: grid;
        grid-template-columns: 36% 36%;
        grid-template-rows: repeat(4,150px);
        -webkit-column-gap: 20px;
        column-gap: 50px;
        row-gap: 10px;
        align-items: center;
        justify-content: center;
        @media (min-width:1600px) {
            height: 80%;
            grid-template-columns: 36% 36%;
            grid-template-rows: repeat(4,200px);
        }
        @media (max-width:1024px) {
            height: 75%;
            grid-template-columns: 73%;
            grid-template-rows: repeat(4,180px);
        }
        @media (max-width:860px) {
            grid-template-columns: 70%;
            grid-template-rows: repeat(4,150px);
        }
        @media (max-width:550px) {
           grid-template-columns: 90%;
        }
        .card{
            display: flex;
            justify-content: center;
            /* width: 80%; */
            height: 100%;
            &:nth-child(2){
                grid-column: 2;
                grid-row: 2;
                @media (max-width:1024px) {
                    grid-column: 1;
                    .texto{
                        padding-left: 30px;
                    }
                }
                @media (max-width:860px) {
                    .texto{
                        padding-left: 50px;
                    }
                }
                @media (max-width:550px) {
                    .texto{
                        padding-left: 20px;
                    }
                }
            }
            &:nth-child(3){
                grid-column: 1;
                grid-row: 3;
            }
            &:nth-child(4){
                grid-column: 2;
                grid-row: 4;
                @media (max-width:1024px) {
                    grid-column: 1;
                    .texto{
                        padding-left: 30px;
                    }
                }
                @media (max-width:860px) {
                    .texto{
                        padding-left: 50px;
                    }
                }
                @media (max-width:550px) {
                    .texto{
                        padding-left: 20px;
                    }
                }
            }
            .texto{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                color: white;
                /* width: 65%; */
                /* height: 80%; */
                h4{
                    font-size: 25px;
                    font-family: "SFProDisplay-semibold";
                    @media (min-width:1600px) {
                        font-size: 35px;
                    }
                    @media (max-width:550px) {
                        font-size: 18px;
                    }
                }
                p{
                    font-size: 15px;
                    width: 77%;
                    @media (min-width:1600px) {
                        font-size: 22px;
                        width: 90%;
                    }
                    @media (max-width:550px) {
                        width: 95%;
                    }
                }
            }
            img{
                height: 145px;
                padding-right: 40px;
                @media (min-width:1600px) {
                    height: 175px;
                    padding-right: 40px;
                }
                @media (max-width:1024px) {
                   padding-right: 0;
                }
                @media (max-width:550px) {
                    height: 100px;
                }
            }
        }
    }
    h1{
        color: #FF3155;
        font-family: "SFProDisplay-Black";
        font-size: 80px;
        text-align: center;
        @media (min-width:1600px) {
            font-size: 100px;
        }
        @media (max-width:860px) {
            font-size: 70px;
            line-height: 60px;
        }
        @media (max-width:550px) {
            font-size: 40px;
            line-height: 40px;
        }
    }
`
const Clientes = styled.div`
    width: 100%;
    height: 280vh;
    background-color: #6A0042;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 2200px;
    @media (max-width:1000px) and (orientation:landscape) {
        min-height: 2030px;
    }
    @media (min-width:1600px) {
        height: 295vh;
    }
    @media (max-width:1024px) {
        height: 210vh;
    }
    @media (max-width:550px) {
        height: 140vh;
        min-height: 1800px;
    }
    .clientes_top{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 150vh;
        min-height: 880px;
        @media (max-width:1000px) and (orientation:landscape) {
            min-height: 500px;
        }
        @media (min-width:1600px) {
            height: 100vh;
        }
        @media (max-width: 550px) {
            min-height: 500px;
        }
        h1{
            color: #FF3155;
            font-family: "renderinc";
            font-size: 130px;
            width: 45%;
            padding-top: 100px;
            @media (min-width:1600px) {
                font-size: 180px;
                width: 50%;
                line-height: 160px;
            }
            @media (max-width:1024px) {
                font-size: 100px;
                line-height: 90px;
                width: 60%;
            }
            @media (max-width:860px) {
                width: 70%;
            }
            @media (max-width:550px) {
                font-size: 60px;
                line-height: 60px;
                padding-top: 50px;
                width: 90%;
            }
        }
        .herramientas_virtual{
            width: 45%;
            display: flex;
            justify-content: space-between;
            @media (min-width:1600px) {
                width: 50%;
            }
            @media (max-width:1024px) {
                width: 80%;
            }
            @media (max-width:550px) {
                width: 90%;
                height: 100px;
                flex-wrap: wrap;
                justify-content: space-around;
            }
            img{
                width: 145px;
                @media (min-width:1600px) {
                    width: 185px;
                }
                @media (max-width:550px) {
                    width: 75px;
                }
                &:nth-child(3),&:nth-child(4){
                    width: 120px;
                    @media (min-width:1600px) {
                        width: 150px;
                    }
                    @media (max-width:550px) {
                        width: 65px;
                    }
                }
            }
        }
        p{
            color: white;
            @media (min-width:1600px) {
                font-size: 22px;
            }
            @media (max-width:550px) {
                text-align: center;
                width: 90%;
            }
        }
    }
    .work_team{
        width: 100%;
        height: 90vh;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 500px;
        @media (max-width:1000px) and (orientation:landscape) {
            min-height: 900px;
        }
        @media (min-width:1600px) {
            height: 100vh;
        }
        @media (max-width:1024px) {
           flex-direction: column;
           padding: 50px 0;
           height: 80vh;
        }
        @media (max-width:550px) {
            height: 130vh;
        }
        .left{
            width: 35%;
            padding-left: 100px;
            @media (min-width:1600px) {
                padding-left: 150px;
            }
            @media (max-width:1024px) {
                width: 100%;
                height: 30%;
                padding: 0 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }
            h2{
                width: 80%;
                margin-bottom: 30px;
                color: #FF3155;
                font-size: 80px;
                line-height: 75px;
                font-family: "SFProDisplay-Black";
                @media (max-width:1024px) {
                    text-align: center;
                    margin: 0;
                    width: 100%;
                }
                @media (max-width:550px) {
                    font-size: 40px;
                    line-height: 40px;
                }
            }
            p{
                width: 100%;
                color: white;
                font-size: 18px;
                @media (min-width:1600px) {
                    width: 80%;
                }
                @media (max-width:1024px) {
                    text-align: center;
                    margin: 0;
                    width: 100%;
                }
            }
        }
        .right{
            width: 60%;
            height: 100%;
            padding-right: 30px;
            @media (min-width:1600px) {
                padding-right: 100px;
            }
            @media (max-width:1024px) {
                width: 100%;
                height: 70%;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .clientes{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 680px;
        /* margin-top: 100px; */
        padding: 50px 0;
        background-color: #1B056A;
        min-height: 800px;
        @media (max-width:1000px) and (orientation:landscape) {
            min-height: 630px;
        }
        @media (min-width:1600px) {
            height: 95vh;
            padding: 80px 0;
        }
        @media (max-width:1024px) {
            padding: 45px 10px;
            height: 80vh;
        }
        @media (max-width:550px) {
            height: 740px;
            margin-top: 0;
            justify-content: space-evenly;
        }
        h3{
            font-size: 50px;
            color: #01F5F9;
            font-family: "SFProDisplay-Black";
            padding-bottom: 20px;
            @media (min-width:1600px) {
                font-size: 60px;
                padding-bottom: 20px;
            }
            @media (max-width:1024px) {
                text-align: center;
            }
            @media (max-width:550px) {
                font-size: 30px;
            }
        }
        .logos-clientes{
            display: grid;
            grid-template-columns: repeat(6,150px);
                grid-template-rows: repeat(7,70px);
            align-items: center;
            justify-items: center;
            @media (min-width:1600px) {
                font-size: 60px;
                grid-template-columns: repeat(6,180px);
                grid-template-rows: repeat(7,80px);
            }
            @media (max-width:1024px) {
                grid-template-columns: repeat(6, 100px);
                grid-template-rows: repeat(7,60px);
            }
            @media (max-width:550px) {
                grid-template-columns: repeat(4, 80px);
                grid-template-rows: repeat(11,40px);
            }
            img{
                    width: 80px;
                    height: 40px;
                @media (min-width:1600px) {
                    width: 100px;
                    height: 50px;
                }
                @media (max-width:1024px) {
                    width: 70px;
                    height: 40px;
                }
                @media (max-width:550px) {
                   width: 50px;
                   height: 30px;
                }
            } 
        }
    }
`
export { 
    Stiky, 
    Hero, 
    Video,
    About, 
    Proyectos, 
    Contacto, 
    Simulador, 
    Button, 
    Footers, 
    Headers, 
    Concepto, 
    Proceso,
    Clientes
};