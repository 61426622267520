export const eng = {
    CONCEPT_RENDERINC: 'Architectural communication agency focused on generating interactive experiences that bring imagination to reality.',
    BTN_SOLUTIONS: 'Our solutions ',
    BTN_PORTFOLIO: 'Portfolio',
    TITLE_SOLUTIONS: 'SOLUTIONS',
    CONCEPT_SOLUTIONS: 'We help you to generate tools for the specialized sale of your real estate project.',
    TITLE_VELASUR: 'INNOVATIVE SELLING TOOLS',
    CONCEPT_VELASUR: 'Integration of HD renderings as sales tools for projects under development.',
    KEY_WORDS_VELASUR: 'VELASUR | QUERÉTARO | WEB SITE | SALES SIMULATOR | PROMOTIONAL VIDEO',
    BTN_PROYECTO: 'View Project',
    TITLE_EVAMAR: 'BOOST YOUR REAL ESTATE PROJECT',
    CONCEPT_EVAMAR: 'Warm spaces that increase the quality of life for families and communities.',
    KEY_WORDS_EVAMAR: 'EVAMAR | SONORA | FULL SERVICE CASE',
    TITLE_NOWUS: 'IMAGINE, VISUALIZE',
    CONCEPT_NOWUS: 'Redefining the office concept with cutting-edge corporate and retail spaces.',
    KEY_WORDS_NOWUS: 'NOWUS-CENTRO SUR| QUERÉTARO | FULL SERVICE CASE',
    TITLE_SIMULADOR: 'SALES SIMULATOR',
    CONCEPT_SIMULADOR: 'Increase your sales with an interactive tool to promote your development online, we show you how it works.',
    BTN_SCHEDULE: 'schedule a demo',
    BTN_DATA: 'Leave us your information to contact you',
    TITLE_LEADS: 'LEAD PROFILING',
    CONCEPT_LEADS: 'Allows to know the project with its characteristics, permitting online comparison with other existing projects according to the current purchase flow of customers.',
    TITLE_CRM: 'CRM',
    CONCEPT_CRM: 'Connect your CRM with your project in a graphical and attractive way, showing updated inventory and tracking your customers.',
    TITLE_PAY: 'RESERVATION WITH ONLINE PAYMENT ',
    CONCEPT_PAY: 'We enable online booking via electronic payment, to encourage express purchase.',
    TITLE_ADMIN: 'ADMINISTRATION PANEL',
    CONCEPT_ADMIN: 'We design a panel tailored to your needs so that you can control the features of your project.',
    TITLE_RESPONSIVE: 'RESPONSIVE',
    CONCEPT_RESPONSIVE: 'Platform friendly with all devices (Tablet, PC, Smartphone) Activity and changes in real time.',
    TITLE_MOCKUPS: 'LINKABLE WITH MOCKUPS',
    CONCEPT_MOCKUPS: 'Integration of your web inventory with physical mockups, through Arduino for an interactive experience.',
    LABEL_NAME: 'Name',
    LABEL_EMAIL: 'Email',
    LABEL_PHONE: 'Phone number',
    LABEL_ABOUT: 'Tell us about your Project',
    MENU_PORTFOLIO: 'PORTFOLIO',
    MENU_RETURN: 'RETURN',
    MENU_SOLUTIONS: 'SOLUTIONS',
    MENU_PROJECTS: 'PROJECTS',
    MENU_INNOVATION: 'INNOVATION',
    MENU_PROCESS: 'PROCESS',
    MENU_CONTACT:'CONTACT',
    MENU_WORK: 'Work with us',
    ARCHITECTONIC: 'ARCHITECTONIC',
    PRODUCT: 'PRODUCT',
    AUTOMOTIVE: 'MEDICAL AUTOMOTIVE',
    CONCEPT: 'CONCEPT',
    INDUSTRIAL: 'INDUSTRIAL',
    FURNITURE: 'FURNITURE',
    ANIMATION:'ANIMATION 3D FILM',
    VIDEO_PRODUCTION:'VIDEO PRODUCTION',
    VIRTUAL_TOURS: 'VIRTUAL TOURS',
    VIDEO_PROMOTIONAL: 'VIDEO PROMOTIONAL',
    ANIMATION2D: 'ANIMATION 2D',
    VIDEO_PRODUCT:'VIDEO PRODUCT',
    VIRTUAL_TOUR: 'VIRTUAL TOUR',
    GAME_ON: 'GAME ON VR 360',
    MATTERPORT: 'MATTERPORT',
    WEBGL_TOUR: 'WEBGL TOUR',
    GRAPHIC_IDENTITY: 'GRAPHIC IDENTITY',
    BRANDING: 'BRANDING',
    APLICATIONS: 'APLICATIONS',
    SALE_BOOK: 'SALE BOOK',
    DEVELOPMENT:'DESIGN AND DEVELOPMENT',
    WEBSITE: 'CUSTOM WEBSITE',
    SALES_SIMULATOR: 'SALES SIMULATOR',
    CHATBOT:'TEST FIT 360 CHAT BOT',
    FINANCIAL_SIMULATOR: 'FINANCIAL SIMULATOR',
    INVENTORY_CONNECTION: 'INVENTORY CONNECTION',
    RESPONSIVE_DESIGN: 'RESPONSIVE DESIGN',
    INTEGRATION: 'INTEGRATION ',
    OURS: 'OF ALL OURS TOOLS',
    TOOLS: ' ',
    GENERATE: ' TO GENERATE YOUR',
    YOUR_METAVERSE: ' METAVERSE',
    RENDERING_3D: 'Creation and visualization of hyperrealistic images.',
    ANIMATION_3DFILM: '3D animation and video production.',
    VIRTUAL_REALITY: 'Design and development of interactive experiences.',
    BRANDING2: 'Integration of tools to unify sales communication',
    WEB_DEVELOPMENT:'We generate comprehensive and innovative solutions.',
    METAVERSE:'Comprehensive presence in the new virtual world',
    SLOGAN_PROCESS: 'HOW WE MAKE IT HAPPEN',
    OUR_UNIVERSE: 'OUR UNIVERSE',
    INTERACT: 'We interact in various industries:',
    RESIDENTIAL: 'RESIDENTIAL',
    INDUSTRIAL_OFFICES: 'INDUSTRIAL OFFICE',
    COMMERCIAL: 'COMMERCIAL',
    ENTERTAINMENT: 'ENTERTAINMENT',
    URBAN_DEVELOPMENT: 'DESARROLLO URBANO',
    METHODOLOGY: 'METHODOLOGY',
    CONTACT_US: 'CONTACT US',
    CONCEPT_CONTACTUS: 'We are available to listen to you, we offer personalized attention through the medium that best suits your needs.',
    TELL_US: 'TELL US ABOUT YOUR PROJECT',
    CONCEPT_TELLUS: 'Share with us the information that you think may be useful for us to understand your project and tell us how you imagine it.',
    ANALYSIS:'ANALYSIS AND BUDGET',
    CONCEPT_ANALYSIS: 'We make sure we understand your project to offer you solutions and the best tools to promote it.',
    WE_PLAN: 'WE PLAN',
    CONCPET_WEPLAN: 'The experience as a client is the most important thing for us, we make sure that the process and response times are satisfactory.',
    WE_MAKE: 'WE MAKE IT REAL',
    DISTANCE: 'With us distance is a thing of the past.',
    CONCEPT_WORKTEAM: 'We are a multidisciplinary team experienced in providing innovative solutions to our clients.',
    HAVE_TRUSTEDUS: 'THEY HAVE TRUSTED US',
    RIGHTS:'All rights reserved',
    PRIVACY: 'Privacy policy'
}